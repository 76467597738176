import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { AddReceiptPage } from '../ui-components';
import {convertBase64} from '../tools/base64'
import { Auth } from 'aws-amplify';
import { apiOobjoEndpoint } from '../tools/api';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import { Loader, Flex } from '@aws-amplify/ui-react';
import ModalWaiting from './ModalWaiting';
import imageCompression from 'browser-image-compression';
import { DataStore } from 'aws-amplify';
import * as config from "../config";

class AddReceipt extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE);
        super(props);
        this.navigate = props.navigate;
        this.receiptRef = React.createRef();
        this.state = {
            receiptCompanyName: undefined,
            receiptPurchaseDate: undefined,
            receiptAmount: undefined,
            receiptCurrency: "€",
            receiptFileObj: undefined,
            showModalWaiting: false
        };
    }

    async saveItem() {
        this.setState({showModalWaiting:true});
        const receipt = {
            companyName: this.state.receiptCompanyName,
            purchaseDate: this.state.receiptPurchaseDate,
            amount: this.state.receiptAmount,
            currency: this.state.receiptCurrency
        }

        if (this.state.receiptFileObj) {
            let fileBlob = this.state.receiptFileObj;
                if (this.state.receiptFileObj.type.startsWith('image')) {
                fileBlob = await imageCompression(this.state.receiptFileObj, 
                    {maxWidthOrHeight: config.RECEIPT_IMAGE_MAX_SIZE, 
                        fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            }
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.receiptFile = { mime: image0Mime, data: image0FileB64 };
        }

        const options = {
            method: 'POST',
            //mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
            },
            body: JSON.stringify(receipt)
        };
        const baseUrl = await apiOobjoEndpoint()
        const response = await fetch(new Request(`${baseUrl}/v1/receipt`, options));
        const body = await response.json();
        if (response.status != 201) {
            throw `Receipt not Created, ResponseStatus=${response.status}`;
        }
        else {
            console.log("Receipt saved finish");
        }
        //await DataStore.stop();
        //await DataStore.start();
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.navigate(-1);
        }, 500);
    }

    render() {
        return (
            <Flex direction="column" alignItems="center" 
            >
                <input ref={this.receiptRef} type="file" style={{ display: 'none' }}
                    onChange={(e) => { this.setState({ receiptFileObj: e.target.files && e.target.files[0] }) }}
                />
                <AddReceiptPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    CompanyNameTextField: {
                        onBlur: (event) => {
                            this.setState({receiptCompanyName:event.target.value});
                        }
                    },
                    PurchaseDateTextField: {
                        type: "date",
                        onBlur: (event) => {
                            this.setState({receiptPurchaseDate:event.target.value});
                        }
                    },
                    AmountTextField: {
                        onBlur: (event) => {
                            this.setState({receiptAmount:event.target.value});
                        }
                    },
                    ReceiptButton: { onClick: (e) => {this.receiptRef.current.click();}},
                    SaveItemButton: { onClick: (e) => {this.saveItem()}},
                }}/>
                
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(AddReceipt);