// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Receipt, Item, ItemAd, Company, User } = initSchema(schema);

export {
  Receipt,
  Item,
  ItemAd,
  Company,
  User
};