/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function CookiesAndCGUPage(props) {
  const { overrides, ...rest } = props;
  const cookiesLinkLabelOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/resources/legals/cookies_policy_2023-02-16.pdf",
  });
  const cGULinkLabelOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/resources/legals/cgu_2023-02-16.pdf",
  });
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(239,240,240,1)"
      {...getOverrideProps(overrides, "CookiesAndCGUPage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="400px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "HeadSection")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "NavSection")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="link"
              children="<"
              {...getOverrideProps(overrides, "TitleBackButton")}
            ></Button>
            <Text
              fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(59,135,165,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Cookies & GCU"
              {...getOverrideProps(overrides, "TitleLabel")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Oobjo utilise des cookies pour réaliser des mesures d'audience.&#xA;En cliquant sur le bouton Tout accepter, &#xA;vous acceptez :"
            {...getOverrideProps(overrides, "LoginDescriptionLabel")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children=" - Notre politique de cookies"
            onClick={() => {
              cookiesLinkLabelOnClick();
            }}
            {...getOverrideProps(overrides, "CookiesLinkLabel")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(0,25,77,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children=" - Nos CGU"
            onClick={() => {
              cGULinkLabelOnClick();
            }}
            {...getOverrideProps(overrides, "CGULinkLabel")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "ButtonsSection")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="default"
            children="Tout refuser et se déconnecter"
            {...getOverrideProps(overrides, "RefuseButton")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Tout accepter"
            {...getOverrideProps(overrides, "AcceptButton")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
