/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function LandingPage(props) {
  const { hostname = "https://dev.oobjo.com", overrides, ...rest } = props;
  const visionLabelOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://youtube.com/shorts/kjq_hGcXT7Y",
  });
  const vousvoulezensavoirplusoudevenirpartenaireColonDcouvrezcequOobjopeutvousapporterencliquanticiGreaterThanGreaterThanOnClick =
    useNavigateAction({ type: "url", url: "/mission" });
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "LandingPage")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="992px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 60px 40px 60px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "IntroSection")}
        >
          <Flex
            gap="50px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 166")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 185")}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="600"
                color="rgba(0,0,0,1)"
                lineHeight="48px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Bienvenue sur "
                {...getOverrideProps(overrides, "HelloLabel")}
              ></Text>
              <Image
                width="115px"
                height="43.13px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/logo192.png"}`}
                {...getOverrideProps(overrides, "logo")}
              ></Image>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="&#xA;Votre application tout-en-un pour organiser et suivre&#xA;vos achats et vos objets en toute simplicité.&#xA;"
              {...getOverrideProps(overrides, "text1Label41223229")}
            ></Text>
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "RegisterSection")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Créer un compte"
                {...getOverrideProps(overrides, "RegisterNowButton")}
              ></Button>
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                isDisabled={false}
                variation="primary"
                children="Vos objets et tickets sur Oobjo !"
                {...getOverrideProps(overrides, "HomeButton")}
              ></Button>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="18px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="&#xA;Commencez à organiser vos achats dès aujourd'hui et faites un geste pour la planète"
                {...getOverrideProps(overrides, "text1Label45121544")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="7px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "previewSection")}
          >
            <View
              width="295px"
              height="356px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ImagesFrame")}
            >
              <Image
                width="100px"
                height="217.61px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="18px"
                left="187px"
                boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/landing_page/preview_img3.jpg"}`}
                {...getOverrideProps(overrides, "preview_img3")}
              ></Image>
              <Image
                width="100px"
                height="217.66px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="72px"
                left="102px"
                boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/landing_page/preview_img2.jpg"}`}
                {...getOverrideProps(overrides, "preview_img2")}
              ></Image>
              <Image
                width="100px"
                height="218.86px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="127px"
                left="17px"
                boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/landing_page/preview_img1.jpg"}`}
                {...getOverrideProps(overrides, "preview_img1")}
              ></Image>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(59,135,165,1)"
              lineHeight="18px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Découvrez notre vision détaillée >>"
              onClick={() => {
                visionLabelOnClick();
              }}
              {...getOverrideProps(overrides, "visionLabel")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 0px 40px 0px"
          backgroundColor="rgba(245,245,245,1)"
          {...getOverrideProps(overrides, "FunctionSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="48px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Fonctionnalités et bénéfices"
            {...getOverrideProps(overrides, "label40276278")}
          ></Text>
          <Flex
            gap="30px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 30px 0px 30px"
            {...getOverrideProps(overrides, "Section1")}
          >
            <Flex
              gap="20px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame1")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Ajoutez automatiquement les tickets de caisse et factures de vos objets à partir d’une photo ou de votre compte fidélité"
                {...getOverrideProps(overrides, "text39122738")}
              ></Text>
            </Flex>
            <Flex
              gap="20px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame2")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Retrouvez facilement les garanties de vos objets et les services disponibles pour les entretenir ou les réparer."
                {...getOverrideProps(overrides, "text45122899")}
              ></Text>
            </Flex>
            <Flex
              gap="20px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame3")}
            >
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Mettez en vente vos objets inutilisés en un clin d’œil, augmentez votre pouvoir d'achat et libérer de l’espace"
                {...getOverrideProps(overrides, "text45122912")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="60px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 60px 40px 60px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "CustomerReviewsSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="48px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Avis clients"
            {...getOverrideProps(overrides, "label45123989")}
          ></Text>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 186")}
          >
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 188")}
            >
              <Image
                width="69px"
                height="68px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/landing_page/julie.jpg"}`}
                {...getOverrideProps(overrides, "customer_img_1")}
              ></Image>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(36,83,101,1)"
                lineHeight="18px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Julie 34 ans"
                {...getOverrideProps(overrides, "Julie 34 ans")}
              ></Text>
            </Flex>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="justify"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="« Avant Oobjo, la gestion de mes objets était un vrai casse-tête. Vendre, réparer, ou recycler, tout devenait une corvée. Mais avec Oobjo, tout est devenu simple. Je vends mes objets en un clic, accède à mes garanties instantanément, et organise mes objets facilement. C'est la solution qu'il me fallait pour libérer de la place, maximiser mon pouvoir d'achat et simplifier ma vie. »"
              {...getOverrideProps(
                overrides,
                "\u00AB Avant Oobjo, la gestion de mes objets \u00E9tait un vrai casse-t\u00EAte. Vendre, r\u00E9parer, ou recycler, tout devenait une corv\u00E9e. Mais avec Oobjo, tout est devenu simple. Je vends mes objets en un clic, acc\u00E8de \u00E0 mes garanties instantan\u00E9ment, et organise mes objets facilement. C'est la solution qu'il me fallait pour lib\u00E9rer de la place, maximiser mon pouvoir d'achat et simplifier ma vie. \u00BB"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 187")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="24px"
              textAlign="justify"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="« Que mes objets soient en bon état ou nécessitent des réparations, je les entassais dans mes placards ou mon garage. Je n’avais pas le courage de les vendre, les donner, les recycler ou les faire réparer. Maintenant, avec Oobjo, tout est devenu simple. Je peux les faire réparer ou les vendre en quelques clics. Oobjo m'a permis de gagner de l’espace et de donner une seconde vie à mes objets non utilisés. »"
              {...getOverrideProps(
                overrides,
                "\u00AB Que mes objets soient en bon \u00E9tat ou n\u00E9cessitent des r\u00E9parations, je les entassais dans mes placards ou mon garage. Je n\u2019avais pas le courage de les vendre, les donner, les recycler ou les faire r\u00E9parer. Maintenant, avec Oobjo, tout est devenu simple. Je peux les faire r\u00E9parer ou les vendre en quelques clics. Oobjo m'a permis de gagner de l\u2019espace et de donner une seconde vie \u00E0 mes objets non utilis\u00E9s. \u00BB"
              )}
            ></Text>
            <Flex
              gap="5px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 189")}
            >
              <Image
                width="68px"
                height="68px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/landing_page/thomas.jpg"}`}
                {...getOverrideProps(overrides, "customer_img_2")}
              ></Image>
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(36,83,101,1)"
                lineHeight="18px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Thomas 42 ans"
                {...getOverrideProps(overrides, "Thomas 42 ans")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="60px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="80px 60px 80px 60px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "BigLogoSection")}
        >
          <Image
            width="512px"
            height="195px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={`${hostname}${"/resources/images/logo_full_512.png"}`}
            {...getOverrideProps(overrides, "logo_full")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 0px 10px 0px"
          backgroundColor="rgba(59,135,165,1)"
          {...getOverrideProps(overrides, "GotoMissionSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="800"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Vous voulez en savoir plus ou devenir partenaire : Découvrez ce qu’Oobjo peut vous apporter en cliquant ici >>"
            onClick={() => {
              vousvoulezensavoirplusoudevenirpartenaireColonDcouvrezcequOobjopeutvousapporterencliquanticiGreaterThanGreaterThanOnClick();
            }}
            {...getOverrideProps(
              overrides,
              "Vous voulez en savoir plus ou devenir partenaire : D\u00E9couvrez ce qu\u2019Oobjo peut vous apporter en cliquant ici >>"
            )}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
