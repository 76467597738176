import React from 'react';
import withRouter from './withRouter';
import { ItemDetailsPage } from "../ui-components";

import { DataStore } from '@aws-amplify/datastore';
import { Storage } from "@aws-amplify/storage";
import { Company, Item, ItemAd, Receipt } from '../models';
import { Auth } from 'aws-amplify';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import imageCompression from 'browser-image-compression';
import * as config from "../config"

import { updateS3SignedUrls, downloadTextFromS3Uri, downloadBinaryFromS3Uri, removeFileFromS3Uri, uploadToS3Uri } from "../data/s3"
import { deleteItemFromDS, deleteItemAdFromDS } from "../data/datastore"
import { elapsedMonths } from '../tools/date';
import { evaluateUsedPrice, giftCertificateAmount } from '../tools/price';

import omit from 'object.omit'
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';

class ItemDetails extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE);
        super(props);
        this.navigate = props.navigate;
        this.image0Ref = React.createRef();
        this.image1Ref = React.createRef();
        this.image2Ref = React.createRef();
        this.state = {
            user: props.user,
            itemId: props.params.id,
            company: undefined,
            companyLogoS3Uri: undefined,
            receiptS3Uri: undefined,
            itemAd: undefined,
            images0S3Uri: undefined,
            images1S3Uri: undefined,
            images2S3Uri: undefined,
            image0FileObj: undefined,
            image1FileObj: undefined,
            image2FileObj: undefined,
            showModalWaiting: false
        };
    }

    async fetchItem() {
        const item = await DataStore.query(Item, this.state.itemId);
        const company = await DataStore.query(Company, item.companyId);
        const receipt = await item.receipt;
        const itemAds = await item.itemAds.toArray();
        var itemAd = undefined;
        if (itemAds.length === 0) {
            let itemDict = JSON.parse(JSON.stringify(item));
            itemDict = omit(itemDict, ['createdAt', 'updatedAt', 'id']);
            itemDict['itemID'] = item.id;
            itemDict['isForSale'] = false;
            itemDict['adName'] = item.name;
            itemDict['adDescription'] = await downloadTextFromS3Uri(item.descriptionS3Uri);
            itemDict['adPrice'] = evaluateUsedPrice(item.price, item.purchaseDate) ?? 0;
            itemDict['adCurrency'] = '€';
            itemDict['adContact'] = this.state.user.attributes.email;
            itemAd = new ItemAd(itemDict)
        }
        else {
            itemAd = itemAds[0];
        }
        return { item, company, receipt, itemAd };
    }

    async deleteItem() {
        this.setState({showModalWaiting:true});
        await deleteItemFromDS(this.state.item);
        this.setState({showModalWaiting:false});
        this.navigate(`/allitems`, { replace: false })
    }

    async deleteItemAd() {
        this.setState({showModalWaiting:true});
        await deleteItemAdFromDS(this.state.itemAd);
        this.setState({showModalWaiting:false});
        this.componentDidMount();
        console.log("item saved")      
    }

    async saveItemAd()
    {
        this.setState({showModalWaiting:true});
        // upload updated images
        let imagesS3Uris = [...this.state.itemAd.imagesS3Uris];
        if (this.state.image0FileObj) {
            const fileBlob = await imageCompression(this.state.image0FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const s3Uri = await uploadToS3Uri(`itemads_image/${this.state.itemAd.id}/image0.jpg`,
                                        fileBlob, "protected", fileBlob.type);
            imagesS3Uris.splice(0,1, s3Uri);
        }
        if (this.state.image1FileObj) {
            const fileBlob = await imageCompression(this.state.image1FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const s3Uri = await uploadToS3Uri(`itemads_image/${this.state.itemAd.id}/image1.jpg`,
                                        fileBlob, "protected", fileBlob.type);
            imagesS3Uris.splice(1,1, s3Uri);
        }
        if (this.state.image2FileObj) {
            const fileBlob = await imageCompression(this.state.image2FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const s3Uri = await uploadToS3Uri(`itemads_image/${this.state.itemAd.id}/image2.jpg`,
                                        fileBlob, "protected", fileBlob.type);
            imagesS3Uris.splice(2,1, s3Uri);
        }

        const itemAdToSave = ItemAd.copyOf(this.state.itemAd, updated => {
            updated.isForSale = true;
            updated.imagesS3Uris = imagesS3Uris;
        })
        await DataStore.save(itemAdToSave);
        console.log("itemAd saved");
        // wait datastore sync
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.navigate(`/itemad/${this.state.itemAd.id}`, { replace: false })
            console.log("itemAd saved")
        }, 500);

    
    }

    componentDidMount() {
        this.fetchItem().then(({ item, company, receipt, itemAd }) => {
            this.setState({
                item: item,
                company: company,
                receipt: receipt,
                itemAd: itemAd, 
                companyLogoS3Uri: undefined,
                receiptS3Uri: undefined,
                images0S3Uri: undefined,
                images1S3Uri: undefined,
                images2S3Uri: undefined,
                image0FileObj: undefined,
                image1FileObj: undefined,
                image2FileObj: undefined,
            }, () => {
            updateS3SignedUrls(company?.companyLogoFileS3Uri, this, "companyLogoS3Uri")
            updateS3SignedUrls(receipt.receiptFileS3Uri, this, "receiptS3Uri");
            updateS3SignedUrls(itemAd.imagesS3Uris[0], this, "images0S3Uri");
            updateS3SignedUrls(itemAd.imagesS3Uris[1], this, "images1S3Uri");
            updateS3SignedUrls(itemAd.imagesS3Uris[2], this, "images2S3Uri");
            });
            //downloadBinaryFromS3Uri(itemAd.imagesS3Uris[0]).then((blob) => {
            //    this.setState({ image0FileObj:blob});
            //});
        });
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <input ref={this.image0Ref} type="file" style={{ display: 'none' }}
                    onChange={(e) => { this.setState({ image0FileObj: e.target.files && e.target.files[0] }) }}
                />
                <input ref={this.image1Ref} type="file" style={{ display: 'none' }}
                    onChange={(e) => { this.setState({ image1FileObj: e.target.files && e.target.files[0] }) }}
                />
                <input ref={this.image2Ref} type="file" style={{ display: 'none' }}
                    onChange={(e) => { this.setState({ image2FileObj: e.target.files && e.target.files[0] }) }}
                />
                <ItemDetailsPage hostname="" item={this.state.item}
                    overrides={{
                        TitleBackButton: { onClick: (e) => { this.navigate(-1); }},
                        SecondHandSectionAdActivatedSection: { display: this.state.itemAd?.isForSale ? "flex" : "none" },
                        ItemImage: { objectFit: "contain", backgroundColor: "white", src: this.state.images0S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                        CompanyImage: { objectFit: "contain", backgroundColor: "white", src: this.state.companyLogoS3Uri ?? DEFAULT_BCKGND_IMG_URL },
                        DeleteItemButton: { onClick: () => { window.confirm("Etes vous sur de vouloir supprimer cet objet ?",) && this.deleteItem() } },
                        ReceiptButton: { onClick: () => { this.navigate(`/receiptdetails/${this.state.receipt.id}`, { replace: false }); } },
                        WarrantyInformation: { children: `Garantie : ${this.state.item?.purchaseDate ? 24 - elapsedMonths(this.state.item?.purchaseDate) : '?'} mois restant` },
                        SecondHandAdCopyLinkButton: {
                            onClick: (e) => {
                                navigator.clipboard.writeText(`${window.location.origin}/itemad/${this.state.itemAd.id}`);
                            }
                        },
                        SecondHandAdResetButton: {
                            onClick: () => {
                                this.deleteItemAd();
                            }
                        },
                        SecondHandAdTitleTextField: {
                            defaultValue: this.state.itemAd?.adName,
                            onBlur: (event) => {
                                this.setState({
                                    itemAd: ItemAd.copyOf(this.state.itemAd, updated => {
                                        updated.adName = (event.target.value)
                                    })
                                });
                            }
                        },
                        //SecondHandItemImage0: { objectFit: "contain", backgroundColor: "white", src: this.state.images0S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                        SecondHandItemImage0: {
                            objectFit: "contain", backgroundColor: "white",
                            src: this.state.image0FileObj ? URL.createObjectURL(this.state.image0FileObj) :
                                this.state.images0S3Uri ?? DEFAULT_BCKGND_IMG_URL,
                            onClick: (e) => {
                                this.image0Ref.current.click();
                            }
                        },
                        //SecondHandItemImage1: { objectFit: "contain", backgroundColor: "white", src: this.state.images1S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                        SecondHandItemImage1: {
                            objectFit: "contain", backgroundColor: "white",
                            src: this.state.image1FileObj ? URL.createObjectURL(this.state.image1FileObj) :
                                this.state.images1S3Uri ?? DEFAULT_BCKGND_IMG_URL,
                            onClick: (e) => {
                                this.image1Ref.current.click();
                            }
                        },
                        //SecondHandItemImage2: { objectFit: "contain", backgroundColor: "white", src: this.state.images2S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                        SecondHandItemImage2: {
                            objectFit: "contain", backgroundColor: "white",
                            src: this.state.image2FileObj ? URL.createObjectURL(this.state.image2FileObj) :
                                this.state.images2S3Uri ?? DEFAULT_BCKGND_IMG_URL,
                            onClick: (e) => {
                                this.image2Ref.current.click();
                            }
                        },
                        SecondHandItemConditionSelectField: {
                            options: ['Neuf - Dans sa boite', 'Excellent - Pas de trace d\'usure', 'Bon - Quelques traces d’usure', 'Moyen - Fonctionnel et utilisable', 'A réparer - Nécessite une réparation'],
                            placeholder: `${this.state.itemAd?.adCondition ?? "Sélectionnez un état"}`,
                            onChange: (event) => {
                                this.setState({
                                    itemAd: ItemAd.copyOf(this.state.itemAd, updated => {
                                        updated.adCondition = (event.target.value)
                                    })
                                });
                            }
                        },
                        SecondHandItemDescriptionTextAreaField: {
                            rows: 12, resize: "vertical", defaultValue: this.state.itemAd?.adDescription,
                            onBlur: (event) => {
                                this.setState({
                                    itemAd: ItemAd.copyOf(this.state.itemAd, updated => {
                                        updated.adDescription = (event.target.value)
                                    })
                                });
                            }
                        },
                        SecondHandItemPriceTextField: {
                            placeholder: this.state.itemAd?.adPrice,
                            defaultValue: this.state.itemAd?.adPrice,
                            onBlur: (event) => {
                                this.setState({
                                    itemAd: ItemAd.copyOf(this.state.itemAd, updated => {
                                        if (isNaN(parseFloat(event.target.value))) {
                                            updated.adPrice = 0
                                        } else {
                                            updated.adPrice = parseFloat(event.target.value)
                                        }
                                    })
                                });
                            }
                        },

                        SecondHandSectionRecommendedPriceLabel: { children: evaluateUsedPrice(this.state.item?.price, this.state.item?.purchaseDate) },
                        SecondHandAdGiftCertificateCompanyLabel: { children: this.state.item?.companyName },
                        SecondHandAdGiftCertificateAmountLabel: { children: `${giftCertificateAmount(this.state.itemAd?.adPrice)} €` },
                        DisplayAndCreateAdButton: {
                            onClick: () => {
                                this.saveItemAd();
                            },
                            display: this.state.itemAd?.isForSale ? "none" : ""
                        },
                        DisplayAdButton: {
                            onClick: () => {
                                this.saveItemAd();
                            },
                            display: this.state.itemAd?.isForSale ? "" : "none"
                        },
                    }} />
                            <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(ItemDetails);