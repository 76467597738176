/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Divider, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function UserNavigationBar(props) {
  const { hostname = "https://dev.oobjo.com", overrides, ...rest } = props;
  const logoImgOnClick = useNavigateAction({ type: "url", url: "/" });
  const homeLinkOnClick = useNavigateAction({ type: "url", url: "/home" });
  const labelOnClick = useNavigateAction({ type: "url", url: "/home" });
  const accountLinkOnClick = useNavigateAction({
    type: "url",
    url: "/useraccount",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "UserNavigationBar")}
      {...rest}
    >
      <Flex
        gap="17px"
        direction="row"
        width="unset"
        height="60px"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="5px 10px 5px 10px"
        backgroundColor="rgba(59,135,165,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Left")}
        >
          <Image
            width="125px"
            height="50px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={`${hostname}${"/resources/images/logo_white_192.png"}`}
            onClick={() => {
              logoImgOnClick();
            }}
            {...getOverrideProps(overrides, "logoImg")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Center")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="90px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Home"
            onClick={() => {
              homeLinkOnClick();
            }}
            {...getOverrideProps(overrides, "HomeLink")}
          ></Text>
        </Flex>
        <View
          width="1px"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "space39874551")}
        ></View>
        <View
          width="1px"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "space39874572")}
        ></View>
        <View
          width="1px"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "space39874591")}
        ></View>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Right")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Se connecter / Créer un compte"
            onClick={() => {
              labelOnClick();
            }}
            {...getOverrideProps(overrides, "label")}
          ></Text>
          <Flex
            gap="10px"
            direction="column"
            width="45px"
            height="45px"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            position="relative"
            borderRadius="92px"
            padding="10px 10px 10px 10px"
            backgroundColor="rgba(255,255,255,1)"
            onClick={() => {
              accountLinkOnClick();
            }}
            {...getOverrideProps(overrides, "AccountLink")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              color="rgba(59,135,165,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="13px"
              height="24px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="O"
              {...getOverrideProps(overrides, "AccountLinkLabel")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Divider
        width="unset"
        height="1px"
        shrink="0"
        alignSelf="stretch"
        size="small"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider")}
      ></Divider>
    </Flex>
  );
}
