export const ViewportWidth = Object.freeze({ 
    DEVICE: "DEVICE", 
    DESKTOP: "DESKTOP", 
    MOBILE: "MOBILE", 

});

export const DEFAULT_BCKGND_IMG_URL = "/resources/images/ui/background_object_oobjo.jpg";

export function setViewport(viewportWidth){
    if (viewportWidth == ViewportWidth.DEVICE) {
        document.getElementById('testViewport').setAttribute('content','width=device-width');
    }
    if (viewportWidth == ViewportWidth.DESKTOP) {
        document.getElementById('testViewport').setAttribute('content','width=992');
    }
    if (viewportWidth == ViewportWidth.MOBILE) {
        document.getElementById('testViewport').setAttribute('content','width=400');
    }

    
}