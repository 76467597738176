import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { AddItemPage } from '../ui-components';
import { Image } from '@aws-amplify/ui-react';
import { Company, Item, ItemAd, Receipt } from '../models';
import {convertBase64} from '../tools/base64'
import { Auth } from 'aws-amplify';
import { apiOobjoEndpoint } from '../tools/api';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import imageCompression from 'browser-image-compression';
import * as config from "../config";

class AddItem extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.receiptRef = React.createRef();
        this.image0Ref = React.createRef();
        this.image1Ref = React.createRef();
        this.image2Ref = React.createRef();
        this.state = {
            item: new Item({}),
            itemName: undefined,
            itemCompanyName: undefined,
            itemReference: undefined,
            itemBrandName: undefined,
            itemPurchaseDate: undefined,
            itemPrice: undefined,
            currency: undefined,
            receiptFileObj: undefined,
            image0FileObj: undefined,
            image1FileObj: undefined,
            image2FileObj: undefined,
            itemDescription: undefined,

            showModalWaiting: false
        };
    }

    async saveItem() {
        this.setState({showModalWaiting:true});
        const receipt = {
            companyName: this.state.itemCompanyName,
            purchaseDate: this.state.itemPurchaseDate,
            items: [
                {
                    name: this.state.itemName,
                    brandName: this.state.itemBrandName,
                    reference: this.state.itemReference,
                    description: this.state.itemDescription,
                    price: this.state.itemPrice,
                    currency: "€",
                    brandName: this.state.brandName,
                    images: []
                }
            ]
        }

        if (this.state.receiptFileObj){
            let fileBlob = this.state.receiptFileObj;
            if (this.state.receiptFileObj.type.startsWith('image')) {
                fileBlob = await imageCompression(this.state.receiptFileObj, 
                    {maxWidthOrHeight: config.RECEIPT_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            }
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.receiptFile={mime: image0Mime, data: image0FileB64 };
        }
        if (this.state.image0FileObj){
            const fileBlob = await imageCompression(this.state.image0FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.items[0].images.push({mime: image0Mime, data: image0FileB64 });
        }
        if (this.state.image1FileObj){
            const fileBlob = await imageCompression(this.state.image1FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.items[0].images.push({mime: image0Mime, data: image0FileB64 });
        }
        if (this.state.image2FileObj){
            const fileBlob = await imageCompression(this.state.image2FileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.items[0].images.push({mime: image0Mime, data: image0FileB64 });
        }

        const options = {
            method: 'POST',
            //mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
            },
            body: JSON.stringify(receipt)
        };
        const baseUrl = await apiOobjoEndpoint()
        const response = await fetch(new Request(`${baseUrl}/v1/receipt`, options));
        const body = await response.json();
        if (response.status != 201) {
            throw `Receipt not Created, ResponseStatus=${response.status}`;
        }
        else {
            console.log("Receipt saved finish");
        }
        //await DataStore.stop();
        //await DataStore.start();
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.navigate(-1);
        }, 0);
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <input ref={this.receiptRef} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({receiptFileObj: e.target.files && e.target.files[0]})}}       
                />
                <input ref={this.image0Ref} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({image0FileObj: e.target.files && e.target.files[0]})}}       
                />
                <input ref={this.image1Ref} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({image1FileObj: e.target.files && e.target.files[0]})}}       
                />
                <input ref={this.image2Ref} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({image2FileObj: e.target.files && e.target.files[0]})}}       
                />
                <AddItemPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    ItemNameTitleTextField: {
                        onBlur: (event) => {
                            this.setState({itemName:event.target.value});
                        }
                    },
                    ItemCompanyTextField: {
                        onBlur: (event) => {
                            this.setState({itemCompanyName:event.target.value});
                        }
                    },
                    ItemReferenceTextField: {
                        onBlur: (event) => {
                            this.setState({itemReference:event.target.value});
                        }
                    },
                    ItemBrandTextField: {
                        onBlur: (event) => {
                            this.setState({itemBrand:event.target.value});
                        }
                    },
                    PurchaseDateTextField: {
                        type: "date",
                        onBlur: (event) => {
                            this.setState({itemPurchaseDate:event.target.value});
                        }
                    },
                    ItemPriceTextField: {
                        type: "number",
                        onBlur: (event) => {
                            this.setState({itemPrice:event.target.value});
                        }
                    },
                    
                    ReceiptButton: { onClick: (e) => {this.receiptRef.current.click();}},

                    ItemImage0: { objectFit: "contain", backgroundColor: "white", 
                    src: this.state.image0FileObj ? URL.createObjectURL(this.state.image0FileObj): DEFAULT_BCKGND_IMG_URL,
                    onClick: (e) => {
                        this.image0Ref.current.click();
                    }},
                    ItemImage1: { objectFit: "contain", backgroundColor: "white", 
                    src: this.state.image1FileObj ? URL.createObjectURL(this.state.image1FileObj): DEFAULT_BCKGND_IMG_URL,
                    onClick: (e) => {
                        this.image1Ref.current.click();
                    }},
                    ItemImage2: { objectFit: "contain", backgroundColor: "white", 
                    src: this.state.image2FileObj ? URL.createObjectURL(this.state.image2FileObj): DEFAULT_BCKGND_IMG_URL,
                    onClick: (e) => {
                        this.image2Ref.current.click();
                    }},

                    ItemDescriptionTextAreaField: {
                        onBlur: (event) => {
                            this.setState({itemDescription:event.target.value});
                        }
                    },

                    SaveItemButton: { onClick: (e) => {this.saveItem()}},
                }}
                />
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(AddItem);