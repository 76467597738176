import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, AuthModeStrategyType, DataStore } from 'aws-amplify';
import { Authenticator, Button} from "@aws-amplify/ui-react";
import { ThemeProvider } from '@aws-amplify/ui-react';
import { createTheme } from '@aws-amplify/ui-react';
import { studioTheme } from './ui-components';
import awsExports from './aws-exports';

import './index.css';
import "@aws-amplify/ui-react/styles.css";

//Amplify.configure(awsExports);
Amplify.configure({
    ...awsExports,
    authenticationFlowType: 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',
    aws_appsync_authenticationType: 'API_KEY',
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
        //authModeStrategyType: AuthModeStrategyType.DEFAULT
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Authenticator.Provider >
            <ThemeProvider theme={studioTheme}>
                    <Router>
                        <App  />
                    </Router>
            </ThemeProvider>
        </Authenticator.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
