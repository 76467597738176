import { Amplify } from 'aws-amplify';
import * as config from "../config";

export async function apiOobjoEndpoint(){
    if (config.DEBUG_LOCAL){
        return "http://localhost:3000";
    } else {
        const ac = Amplify.configure();
        return ac.aws_cloud_logic_custom[0]['endpoint'];
    }

}
