import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls, imageThumbnailS3Uri} from "../data/s3"
import { DataStore } from '@aws-amplify/datastore';
import { Company } from '../models';
import { AllReceiptsPage, ListedReceiptCollection } from "../ui-components";
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';

import moment from 'moment';
import 'moment/locale/fr'

class AllReceipts extends React.Component {
  constructor(props) {
    setViewport(ViewportWidth.MOBILE); 
    super(props);
    this.navigate = props.navigate;
    this.state = {
    };
  }

  render() {
    return (
      <AllReceiptsPage hostname="" 
      overrides={{
        TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
      }}
      receiptsList={
        <ListedReceiptCollection overrideItems={({ item, index }) => {
          let companyImageStateKey = undefined;
          if (item.companyId) {
            companyImageStateKey = `companyImage_${item.companyId.replace(/[\W_]+/g, "")}`;
            DataStore.query(Company, item.companyId).then((company)=> {
              if (company?.companyLogoFileS3Uri)
                updateS3SignedUrls(imageThumbnailS3Uri(company.companyLogoFileS3Uri), this, companyImageStateKey);
            });
          } 
          //const receiptFileStateKey = updateS3SignedUrls(item.receiptFileS3Uri, this);
          return ({
            //onClick: () => { window.open(this.state[receiptFileStateKey]) },
            onClick: () => { this.navigate(`/receiptdetails/${item.id}`, { replace: false }) },
            overrides: {
              CompanyImage: { src: this.state[companyImageStateKey] ?? DEFAULT_BCKGND_IMG_URL},
              ReceiptNameLabel: { children: item.name },
              PurchaseDateLabel: { children: moment(new Date(item.createdAt)).locale('fr').format('ll') },
              ReceiptAmountLabel: { children: `${item.amount} ${item.currency}` }
            }
          })
        }} />
      } />
    );
  }
}

export default withRouter(AllReceipts);