/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function SecondHandItemAdPage(props) {
  const {
    hostname = "https://dev.oobjo.com",
    itemAd,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "SecondHandItemAdPage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="400px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 0px 10px"
          {...getOverrideProps(overrides, "NavSection")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="large"
            isDisabled={false}
            variation="link"
            children="<"
            {...getOverrideProps(overrides, "TitleBackButton")}
          ></Button>
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(59,135,165,1)"
            lineHeight="30px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="En vente actuellement :"
            {...getOverrideProps(overrides, "TitleLabel")}
          ></Text>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor="rgba(149,4,4,1)"
            size="default"
            isDisabled={false}
            variation="primary"
            children="-"
            {...getOverrideProps(overrides, "TitleRemoveButton")}
          ></Button>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          border="2px SOLID rgba(59,135,165,1)"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "HeadSection")}
        >
          <Flex
            gap="0"
            direction="column"
            width="400px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 0px 10px 0px"
            backgroundColor="rgba(59,135,165,1)"
            {...getOverrideProps(overrides, "TitleSection")}
          >
            <Text
              fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(255,255,255,1)"
              lineHeight="30px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="380px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Meuleuse d'angle"
              {...getOverrideProps(overrides, "ItemNameLabel")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="380px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={itemAd?.reference}
              {...getOverrideProps(overrides, "ItemReferenceLabel")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="column"
            width="400px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "ImagesSection")}
          >
            <View
              width="unset"
              height="270px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "ImageSection")}
            >
              <Image
                width="100%"
                height="100.75%"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="-0.37%"
                bottom="-0.38%"
                left="0%"
                right="0%"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={`${hostname}${"/resources/images/ui/4figma/ItemImage3.jpg"}`}
                {...getOverrideProps(overrides, "ItemImage")}
              ></Image>
              <Image
                width="146px"
                height="50px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                bottom="9px"
                right="10px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/4figma/CompanyImage3.jpg"}`}
                {...getOverrideProps(overrides, "CompanyImage")}
              ></Image>
            </View>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 20px 0px 20px"
              {...getOverrideProps(overrides, "PriceAndWarrantySection")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "SecondHandItemPrice")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Prix : "
                  {...getOverrideProps(
                    overrides,
                    "SecondHandSellingPriceLabel"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={itemAd?.adPrice}
                  {...getOverrideProps(overrides, "SecondHandAdPriceLabel")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="25px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children={itemAd?.adCurrency}
                  {...getOverrideProps(overrides, "SecondHandAdCurencyLabel")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Garantie : 5 mois restant"
                {...getOverrideProps(overrides, "WarrantyInformation")}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "SecondHandAdImagesSection")}
            >
              <Image
                width="100px"
                height="100px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
                {...getOverrideProps(overrides, "SecondHandItemImage0")}
              ></Image>
              <Image
                width="100px"
                height="100px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
                {...getOverrideProps(overrides, "SecondHandItemImage1")}
              ></Image>
              <Image
                width="100px"
                height="100px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
                {...getOverrideProps(overrides, "SecondHandItemImage2")}
              ></Image>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="10px 10px 10px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "SecondHandSellingSection")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 181")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Détails"
              {...getOverrideProps(overrides, "SecondHandSectionTitleLabel")}
            ></Text>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="link"
              children="Copier le lien de l'annonce"
              {...getOverrideProps(overrides, "AdCopyLinkButton")}
            ></Button>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "ItemCondition")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="800"
              color="rgba(48,64,80,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Etat de l'objet : "
              {...getOverrideProps(overrides, "ItemConditionTitleLabel")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={itemAd?.adCondition}
              {...getOverrideProps(overrides, "ItemConditionLabel")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="800"
            color="rgba(48,64,80,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Description de l'annonce :"
            {...getOverrideProps(overrides, "ItemDescriptionTitleLabel")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={itemAd?.adDescription}
            {...getOverrideProps(overrides, "ItemDescriptionLabel")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "ContactSection")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="small"
            isDisabled={false}
            variation="link"
            children="Afficher le mail du vendeur"
            {...getOverrideProps(overrides, "ShowContactButton")}
          ></Button>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "SellerContactSection")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Contact :"
              {...getOverrideProps(overrides, "SellerContactLabel")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={itemAd?.adContact}
              {...getOverrideProps(overrides, "SellerInfoLabel")}
            ></Text>
          </Flex>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Contacter le vendeur par mail"
            {...getOverrideProps(overrides, "ContactSellerButton")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
