
function base64MimeType(encoded) {
    if (!encoded) return;
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) return mime[1];
  }

export function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        const mime = base64MimeType(fileReader.result);
        resolve([fileReader.result.split(',')[1], mime]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };