import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { AllItemAdsPage, ListedItemAdCollection } from '../ui-components';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import { deleteItemFromDS, deleteItemAdFromDS } from "../data/datastore"

class AllItemAds extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
        };
    }

    render() {
        return (
            <AllItemAdsPage hostname="" 
                overrides={{ TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}}
            }}
            itemAdsList={
                <ListedItemAdCollection 
                    overrideItems={({ item, index }) => {
                        const itemImageStateKey = updateS3SignedUrls(item.imagesS3Uris[0], this);
                        if (!item.itemID) {
                            deleteItemAdFromDS(item);
                            console.log(`Ad ${item.id} has been deleted because linked to undefined item`);
                        }
                        return ({
                            onClick: () => {
                                this.navigate(`/itemdetails/${item.itemID}`, { replace: false })
                            },
                            overrides: { ItemImage: { src: this.state[itemImageStateKey] ?? DEFAULT_BCKGND_IMG_URL } }
                        })
                    }}
                 />
            } />
        );
    }
}

export default withRouter(AllItemAds);