/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Flex,
  Image,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  View,
} from "@aws-amplify/ui-react";
export default function ItemDetailsPage(props) {
  const {
    hostname = "https://dev.oobjo.com",
    item,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "ItemDetailsPage")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="400px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="10px 10px 10px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "HeadSection")}
        >
          <View
            width="unset"
            height="270px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "ImageSection")}
          >
            <Image
              width="100%"
              height="100.75%"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="-0.37%"
              bottom="-0.38%"
              left="0%"
              right="0%"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={`${hostname}${"/resources/images/ui/4figma/ItemImage3.jpg"}`}
              {...getOverrideProps(overrides, "ItemImage")}
            ></Image>
            <Image
              width="146px"
              height="50px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              bottom="9px"
              right="10px"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              padding="0px 0px 0px 0px"
              objectFit="contain"
              src={`${hostname}${"/resources/images/ui/4figma/CompanyImage1.jpg"}`}
              {...getOverrideProps(overrides, "CompanyImage")}
            ></Image>
            <Button
              width="unset"
              height="unset"
              position="absolute"
              top="0px"
              right="0px"
              backgroundColor="rgba(149,4,4,1)"
              size="default"
              isDisabled={false}
              variation="primary"
              children="-"
              {...getOverrideProps(overrides, "DeleteItemButton")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              position="absolute"
              top="0px"
              left="0px"
              size="large"
              isDisabled={false}
              variation="link"
              children="<"
              {...getOverrideProps(overrides, "TitleBackButton")}
            ></Button>
          </View>
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={item?.name}
            {...getOverrideProps(overrides, "ItemNameLabel")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={item?.reference}
            {...getOverrideProps(overrides, "ItemReferenceLabel")}
          ></Text>
          <Flex
            gap="30px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 15px 10px 15px"
            {...getOverrideProps(overrides, "DocumentButtonSection")}
          >
            <Button
              width="unset"
              height="unset"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={true}
              variation="default"
              children="Notice"
              {...getOverrideProps(overrides, "ManualButton")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              variation="default"
              children="Facture"
              {...getOverrideProps(overrides, "ReceiptButton")}
            ></Button>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Garantie : 5 mois restant"
            {...getOverrideProps(overrides, "WarrantyInformation")}
          ></Text>
        </Flex>
        <Flex
          gap="15px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="2px SOLID rgba(59,135,165,1)"
          padding="17px 8px 17px 8px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "SecondHandSellingSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(59,135,165,1)"
            lineHeight="30px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Vendre d’occasion"
            {...getOverrideProps(overrides, "SecondHandSectionTitleLabel")}
          ></Text>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(
              overrides,
              "SecondHandSectionAdActivatedSection"
            )}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="800"
              color="rgba(59,135,165,1)"
              lineHeight="20px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Annonce active"
              {...getOverrideProps(
                overrides,
                "SecondHandSectionAdActivatedLabel"
              )}
            ></Text>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="link"
              children="Copier le lien"
              {...getOverrideProps(overrides, "SecondHandAdCopyLinkButton")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Desactiver"
              {...getOverrideProps(overrides, "SecondHandAdResetButton")}
            ></Button>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            label="Titre de l'annonce"
            placeholder="Ajoutez le titre de l’annonce ici"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={item?.name}
            {...getOverrideProps(overrides, "SecondHandAdTitleTextField")}
          ></TextField>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Images"
            {...getOverrideProps(
              overrides,
              "SecondHandSectionImagesDescriptionLabel"
            )}
          ></Text>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "SecondHandAdImagesSection")}
          >
            <Image
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              padding="0px 0px 0px 0px"
              objectFit="contain"
              src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
              {...getOverrideProps(overrides, "SecondHandItemImage0")}
            ></Image>
            <Image
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              padding="0px 0px 0px 0px"
              objectFit="contain"
              src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
              {...getOverrideProps(overrides, "SecondHandItemImage1")}
            ></Image>
            <Image
              width="100px"
              height="100px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              padding="0px 0px 0px 0px"
              objectFit="contain"
              src={`${hostname}${"/resources/images/ui/background_object_oobjo.jpg"}`}
              {...getOverrideProps(overrides, "SecondHandItemImage2")}
            ></Image>
          </Flex>
          <SelectField
            width="unset"
            height="unset"
            label="Etat de l'objet"
            placeholder="Sélectionnez l’état ..."
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(
              overrides,
              "SecondHandItemConditionSelectField"
            )}
          ></SelectField>
          <TextAreaField
            width="unset"
            height="unset"
            label="Description de l’annonce"
            descriptiveText="Modifier de texte de l'annonce générée par oobjo..."
            placeholder="Le texte de l’annonce généré ici..."
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(
              overrides,
              "SecondHandItemDescriptionTextAreaField"
            )}
          ></TextAreaField>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "PriceSection")}
          >
            <Flex
              gap="10px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "SecondHandAdPriceSection")}
            >
              <TextField
                width="118px"
                height="unset"
                label="Prix en €"
                placeholder="99.99"
                shrink="0"
                size="default"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                {...getOverrideProps(overrides, "SecondHandItemPriceTextField")}
              ></TextField>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="et"
                {...getOverrideProps(overrides, "SecondHandItemPriceAndLabel")}
              ></Text>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(
                  overrides,
                  "SecondHandAdGiftCertificateSection"
                )}
              >
                <Flex
                  gap="0"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="10px 10px 10px 10px"
                  {...getOverrideProps(
                    overrides,
                    "SecondHandAdGiftCertificateSubSection"
                  )}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Bon de réduction"
                    {...getOverrideProps(
                      overrides,
                      "SecondHandAdGiftCertificateLabel"
                    )}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Castorama"
                    {...getOverrideProps(
                      overrides,
                      "SecondHandAdGiftCertificateCompanyLabel"
                    )}
                  ></Text>
                </Flex>
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="30px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="80€"
                  {...getOverrideProps(
                    overrides,
                    "SecondHandAdGiftCertificateAmountLabel"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="5px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "PriceAdvice")}
            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Conseillé : "
                {...getOverrideProps(
                  overrides,
                  "SecondHandSectionRecommendedPriceTitleLabel"
                )}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="99"
                {...getOverrideProps(
                  overrides,
                  "SecondHandSectionRecommendedPriceLabel"
                )}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="€"
                {...getOverrideProps(
                  overrides,
                  "SecondHandSectionRecommendedCurrencyLabel"
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="15px 0px 0px 0px"
            {...getOverrideProps(overrides, "ButtonSection")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Créer l’annonce et partager son URL"
              {...getOverrideProps(overrides, "DisplayAndCreateAdButton")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Mettre à jour et afficher l’annonce"
              {...getOverrideProps(overrides, "DisplayAdButton")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
