import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { DataStore } from '@aws-amplify/datastore';
import { Company } from '../models';
import { AllReceiptsPage, ListedReceiptCollection } from "../ui-components";
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import { Loader, Flex, Text} from '@aws-amplify/ui-react';

import moment from 'moment';
import 'moment/locale/fr'

class ModalWaiting extends React.Component {
  constructor(props) {
    setViewport(ViewportWidth.MOBILE); 
    super(props);
    this.navigate = props.navigate;
    this.state = {
    };
  }

  render() {
    return (
        <>
        <div style={{ display:this.props.show ? "unset":"none", backgroundColor:'black', position: 'fixed', top:'0', width:'100%', height:"100%", opacity : 0.75}} >    
        </div>
        <Loader variation="linear"  size="small" style={{ display:this.props.show ? "unset":"none", position: 'fixed', top:'40%', width:'280px',overflowY: "scroll"}} ></Loader>
        <Text
    variation="primary"
    as="p"
    color="white"
    lineHeight="1.5em"
    fontWeight={400}
    fontSize="1em"
    fontStyle="normal"
    style={{ display:this.props.show ? "unset":"none", position: 'fixed', top:'45%', overflowY: "scroll"}}
  >
    Veuillez patienter...
</Text>
        </>
    );
  }
}

export default withRouter(ModalWaiting);