import React from 'react';
import withRouter from './withRouter';
import { LandingPage } from "../ui-components";
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';
import ReactPlayer from  'react-player/youtube'

class Landing extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.DESKTOP); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
        };
    }

    isLoggedIn() {
        return this.props?.authUser?.username ? true : false;
    }
    
    render() {
        return (

            <LandingPage hostname="" 
            //videoFrame={
                //<iframe width="184" height="400" src="https://www.youtube.com/embed/os-0nRED06E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1"></iframe>
                //<div className='player-wrapper' style={{  position: 'relative', paddingTop: '20.25%', width:100, height:100}} >
                // Long version : 
                //<ReactPlayer url='https://youtu.be/y6LdK3GMOKg' playing={true} controls={true}  width="184px" height="400px" style={{ }} />
                // Short Version
                //<ReactPlayer url='https://youtube.com/shorts/kjq_hGcXT7Y' playing={true} controls={true}  width="225px" height="400px" style={{ }} />
                //</div>
            //}
            overrides={{
                RegisterNowButton: { 
                    display: this.isLoggedIn() ? "none" : "unset" ,
                    onClick: (e) => { this.navigate("/home",{ replace: false})}
                },
                HomeButton: {
                    display: this.isLoggedIn() ? "unset" : "none" ,
                    onClick: (e) => { this.navigate("/home",{ replace: false})}
            }
            }}
            />
        );
    }
}

export default withRouter(Landing);