import { API, graphqlOperation } from 'aws-amplify';


export async function fetchItemAd(itemAdId) {
  const getItemAdQuery = /* GraphQL */ `
  query GetItemAd($id: ID!) {
    getItemAd(id: $id) {
      id
      itemID
      companyId
      companyName
      purchaseDate
      reference
      name
      descriptionS3Uri
      imagesS3Uris
      adName
      adDescription
      adCondition
      adContact
      adPrice
      adCurrency
      isForSale
      owner
      _deleted
    }
  }
`;
  const getCompanyQuery = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      companyLogoFileS3Uri
    }
  }
`;
  // avec l'apikey
  const respItemAd = await API.graphql(graphqlOperation(getItemAdQuery, { id: itemAdId }));
  const itemAd = respItemAd.data.getItemAd;
  let company = undefined;
  if (itemAd.companyId) {
    const respCompany = await API.graphql(graphqlOperation(getCompanyQuery, { id: itemAd.companyId }));
    company = respCompany.data.getCompany;
  }
  console.log("ItemAd fetched");
  return {itemAd, company};
}

