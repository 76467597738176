/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://j4dwnwtvcfhh7gmawbj227gkjy.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-txsa36w25bd4xenpcpn5jw677a",
    "aws_cloud_logic_custom": [
        {
            "name": "v1",
            "endpoint": "https://81tt2137g4.execute-api.eu-west-3.amazonaws.com/prod",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:655e83c5-d1ae-4a06-8871-a53678572530",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_32d1IquOb",
    "aws_user_pools_web_client_id": "5ri5c7chis9nqokqpj4m1m9019",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "oobjo-storage10334-prod",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
