import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls, imageThumbnailS3Uri } from "../data/s3"
import { AllItemsPage, ListedItemCollection } from '../ui-components';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';

class AllItems extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
        };
    }

    render() {
        return (
            <AllItemsPage hostname="" 
            overrides={{
                TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
            }}
            itemsList={
                <ListedItemCollection overrideItems={({ item, index }) => {
                    const itemImageStateKey = updateS3SignedUrls(imageThumbnailS3Uri(item.imagesS3Uris[0]), this);
                    return ({
                        onClick: () => {
                            this.navigate(`/itemdetails/${item.id}`, { replace: false })
                        },
                        overrides: { ItemImage: { src: this.state[itemImageStateKey] ?? DEFAULT_BCKGND_IMG_URL } }
                    })
                }} />
            } />
        );
    }
}

export default withRouter(AllItems);