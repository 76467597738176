import { Storage } from "@aws-amplify/storage";
import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { updateS3SignedUrls, downloadTextFromS3Uri, downloadBinaryFromS3Uri, removeFileFromS3Uri, uploadToS3Uri } from "./s3"
import { Item } from "../models";

async function deleteItemAdFiles(itemAd) {
    for (var key in itemAd.imagesS3Uris) {
        await removeFileFromS3Uri(itemAd.imagesS3Uris[key]);
    }
    await removeFileFromS3Uri(itemAd.descriptionS3Uri);
    
}

export async function deleteItemAdFromDS(itemAd) {
    await deleteItemAdFiles(itemAd);
    await DataStore.delete(itemAd);
}

async function deleteItemFiles(item) {
    if (!item.certified) {
        for (var key in item.imagesS3Uris) {
            await removeFileFromS3Uri(item.imagesS3Uris[key]);
        }
        await removeFileFromS3Uri(item.descriptionS3Uri);
    }
    const itemAds = await item.itemAds.toArray();
    for (var key in itemAds) {
        const itemAd = itemAds[key];
        for (var key in itemAd.imagesS3Uris) {
            await removeFileFromS3Uri(itemAd.imagesS3Uris[key]);
        }
        await removeFileFromS3Uri(itemAd.descriptionS3Uri);   
    }
}

export async function deleteItemFromDS(item) {
    await deleteItemFiles(item);
    // linked itemAd will be automaticaly deleted with item
    await DataStore.delete(item);
}



export async function deleteReceiptFromDS(receipt) {
    if (receipt.id) {
        const items = await receipt.items.toArray();
        for (const index in items) {
            deleteItemFiles(items[index]);
        }
        if (receipt.receiptFileS3Uri)
            await removeFileFromS3Uri(receipt.receiptFileS3Uri);
        
        if (receipt.anonymousReceiptFileS3Uri)
            await removeFileFromS3Uri(receipt.anonymousReceiptFileS3Uri);

        await DataStore.delete(receipt);
    }
}