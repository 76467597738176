/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image } from "@aws-amplify/ui-react";
export default function FooterBar(props) {
  const { hostname = "https://dev.oobjo.com", overrides, ...rest } = props;
  const blogButtonOnClick = useNavigateAction({
    type: "url",
    url: "https://blog.oobjo.com/",
  });
  const contactButtonOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "mailto:contact@oobjo.com",
  });
  const legalButtonOnClick = useNavigateAction({ type: "url", url: "/legals" });
  const linkedInUnderScorelogoOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://www.linkedin.com/company/oobjo",
  });
  const faceBookFUnderScorelogoOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "https://www.facebook.com/myoobjo",
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="10px 10px 0px 10px"
      {...getOverrideProps(overrides, "FooterBar")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(250,250,250,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 10px 0px 10px"
          {...getOverrideProps(overrides, "FooterSection")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="Blog"
            onClick={() => {
              blogButtonOnClick();
            }}
            {...getOverrideProps(overrides, "BlogButton")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="Contact"
            onClick={() => {
              contactButtonOnClick();
            }}
            {...getOverrideProps(overrides, "contactButton")}
          ></Button>
          <Button
            width="78px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="Mentions legales"
            onClick={() => {
              legalButtonOnClick();
            }}
            {...getOverrideProps(overrides, "LegalButton")}
          ></Button>
          <Image
            width="30px"
            height="30px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="3px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={`${hostname}${"/resources/images/linkedIn_logo.png"}`}
            onClick={() => {
              linkedInUnderScorelogoOnClick();
            }}
            {...getOverrideProps(overrides, "linkedIn_logo")}
          ></Image>
          <Image
            width="30px"
            height="30px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="3px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={`${hostname}${"/resources/images/facebook_logo.png"}`}
            onClick={() => {
              faceBookFUnderScorelogoOnClick();
            }}
            {...getOverrideProps(overrides, "FaceBookF_logo")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
