import React from 'react';
import withRouter from './withRouter';
import { SecondHandItemAdPage } from "../ui-components";

import { fetchItemAd } from "../data/graphgl"
import { updateS3SignedUrls } from "../data/s3"

import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';

class SecondHandItemAd extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
            itemAdId: props.params.id,
            company: undefined,
            companyLogoS3Uri: undefined,
            itemAd: undefined,
            itemBoughtMonths: undefined,
            images0S3Uri: undefined,
            images1S3Uri: undefined,
            images2S3Uri: undefined,
            contactMailDisplayed: false,
        };
        this.itemAdLoadingId = undefined;
    }

    isLoggedIn() {
        return this.props?.authUser?.username ? true : false;
    }

    isMyItemAd() {
        if (this.isLoggedIn()) {
            const username = this.props?.authUser?.username;
            const owner = this.state?.itemAd?.owner;
            return username == owner;
        }
        return false;
    }

    componentDidMount() {
        if (this.itemAdLoadingId !== this.state.itemAdId) {
            this.itemAdLoadingId = this.state.itemAdId;
            fetchItemAd(this.state.itemAdId).then(({itemAd, company}) => {
                if (itemAd && !itemAd._deleted) {
                    this.setState({
                        company: company,
                        itemAd: itemAd,
                        itemBoughtMonths: itemAd.purchaseDate ? Math.ceil((new Date() - new Date(itemAd.purchaseDate)) / 1000 / 3600 / 24 / 30.5): undefined
                    });
                    updateS3SignedUrls(company?.companyLogoFileS3Uri, this, "companyLogoS3Uri")
                    updateS3SignedUrls(itemAd.imagesS3Uris[0], this, "images0S3Uri")
                    updateS3SignedUrls(itemAd.imagesS3Uris[1], this, "images1S3Uri")
                    updateS3SignedUrls(itemAd.imagesS3Uris[2], this, "images2S3Uri")
                } else {
                    this.navigate("/noitems", { replace: false });
                }
            });
        }
    }

    render() {
        return (
            <SecondHandItemAdPage hostname="" item={this.state.itemAd}
                overrides={{
                    TitleBackButton: { display: this.isMyItemAd() ? "unset" : "none",
                    onClick: (e) => { this.navigate(-1); }
                },
                    TitleRemoveButton: { display: this.isMyItemAd() ? "none" : "none"},
                    ItemNameLabel: { children: this.state.itemAd?.adName },
                    ItemImage: { objectFit: "contain", backgroundColor: "white", src: this.state.images0S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    CompanyImage: { objectFit: "contain", backgroundColor: "white", src: this.state.companyLogoS3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    WarrantyInformation: { children: `Garantie : ${this.state.itemBoughtMonths ? 24 - this.state.itemBoughtMonths : "?"} mois restant` },
                    AdCopyLinkButton: { onClick: (e) => { navigator.clipboard.writeText(window.location.toString()); } },
                    SecondHandItemImage0: { objectFit: "contain", backgroundColor: "white", src: this.state.images0S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    SecondHandItemImage1: { objectFit: "contain", backgroundColor: "white", src: this.state.images1S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    SecondHandItemImage2: { objectFit: "contain", backgroundColor: "white", src: this.state.images2S3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    ItemConditionLabel: { children: this.state.itemAd?.adCondition },
                    ItemDescriptionLabel: { children: this.state.itemAd?.adDescription },
                    SecondHandAdPriceLabel: { children: this.state.itemAd?.adPrice },
                    SecondHandAdCurencyLabel: { children: this.state.itemAd?.adCurrency },
                    ShowContactButton:{ onClick: (e) => { this.setState({contactMailDisplayed:true}) } },
                    SellerContactSection: { display: this.state.contactMailDisplayed ? "unset" : "none"},
                    SellerInfoLabel: { children: this.state.itemAd?.adContact},

                    ContactSellerButton: { onClick: (e) => { window.open(`mailto:${this.state.itemAd.adContact}`) } }
                }} />
        );
    }
}

export default withRouter(SecondHandItemAd);