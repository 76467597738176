import React from 'react';
import withRouter from './withRouter';
import { Button, Heading } from '@aws-amplify/ui-react';
import { HomePage, CompanyHomePage } from '../ui-components';
import UserNavBar from './UserNavBar';
import { DataStore } from 'aws-amplify';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';

class AllItems extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.signOut = props.signOut;
        this.state = {
            
        };  
    }

    render() {
        return (
                <main >
                {this.props?.authUser?.signInUserSession?.accessToken?.payload && !('cognito:groups' in this.props.authUser.signInUserSession.accessToken.payload) &&
                    <div>
                        <UserNavBar authUser={this.props.authUser}  />
                        <HomePage hostname=""
                            overrides={{
                                ItemsButton: {onClick: (e) => { this.navigate("/allitems",{ replace: false})}},
                                ItemsDescriptionLabelFrame: {onClick: (e) => { this.navigate("/allitems",{ replace: false})}},
                                ReceiptsButton: {onClick: (e) => { this.navigate("/allreceipts",{ replace: false})}},
                                ReceiptsDescriptionLabelFrame: {onClick: (e) => { this.navigate("/allreceipts",{ replace: false})}},
                                AdsButton: {onClick: (e) => { this.navigate("/allitemads",{ replace: false})}},
                                AdsDescriptionLabelFrame: {onClick: (e) => { this.navigate("/allitemads",{ replace: false})}},
                                AddButton: {onClick: (e) => { this.navigate("/addreceiptitem",{ replace: false})}}
                            }}
                        />
                    </div>
                }
                {this.props?.authUser?.signInUserSession?.accessToken?.payload && this.props?.authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] == "company" &&
                    <div>
                        <UserNavBar authUser={this.props.authUser}  />
                        <CompanyHomePage hostname=""/>
                    </div>
                }
                {this.props?.authUser?.signInUserSession?.accessToken?.payload && this.props?.authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] == "admin" &&
                    <div>
                        <UserNavBar authUser={this.props.authUser}  />
                        <Heading level={5}>Bonjour {this.props.authUser.attributes.email}</Heading>
                        <p>Oobjo est en éclosion. <br /><br />
                            Nous travaillons à créer le meilleur service pour gérer simplement vos objets.<br />
                            Nous vous tiendrons informé de chaque étape du développement d'Oobjo et vous serez invité à tester Oobjo en avant-première.<br /><br />
                            En attendant : suivez nous sur <a href='https://www.linkedin.com/company/oobjo'>notre page linkedIn</a> ou <a href='https://www.facebook.com/myoobjo'>notre page Facebook</a>
                        </p>
                        <Button onClick={(e) => { this.navigate("/", { replace: false }); }}>Retournez sur la page principale</Button>
                    </div>
                }
            </main>
        );
    }
}

export default withRouter(AllItems);