import { elapsedMonths } from "./date";

export function evaluateUsedPrice(price, purchaseDate){
    if (price && purchaseDate)
        return Math.round(price * .8 * (24 - elapsedMonths(purchaseDate)) / 24);
    else
        return undefined;
}

export function giftCertificateAmount(price)
{
    if (price)
        return Math.round(price * 0.1);
    else
        return 0;
}