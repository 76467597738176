/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Divider, Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function MissionPage(props) {
  const {
    hostname = "https://dev.oobjo.com",
    receipt,
    overrides,
    ...rest
  } = props;
  const pitchOobjoOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: `${hostname}${"/private/presentation/pitchdeck2311.ppsx"}`,
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "MissionPage")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="992px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 0px 40px 0px"
          backgroundColor="rgba(250,250,250,1)"
          {...getOverrideProps(overrides, "WhySection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(59,135,165,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Pourquoi Oobjo ?"
            {...getOverrideProps(overrides, "label451211467")}
          ></Text>
          <Flex
            gap="60px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 60px 0px 60px"
            {...getOverrideProps(overrides, "Section1")}
          >
            <Flex
              gap="20px"
              direction="column"
              width="250.67px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame1451211469")}
            >
              <Image
                width="unset"
                height="150px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/mission_page/second_hand_platform.jpg"}`}
                {...getOverrideProps(overrides, "image 4")}
              ></Image>
              <Divider
                width="unset"
                height="1px"
                shrink="0"
                alignSelf="stretch"
                size="small"
                orientation="horizontal"
                {...getOverrideProps(overrides, "Divider451211471")}
              ></Divider>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Nous sommes inscrits en moyenne sur 9 services permettant de revendre, échanger et recycler nos objets"
                {...getOverrideProps(overrides, "mantraLabel451211472")}
              ></Text>
            </Flex>
            <Flex
              gap="20px"
              direction="column"
              width="250.67px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame2451211473")}
            >
              <Image
                width="unset"
                height="150px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/mission_page/home_mess.jpg"}`}
                {...getOverrideProps(overrides, "image 5")}
              ></Image>
              <Divider
                width="unset"
                height="1px"
                shrink="0"
                alignSelf="stretch"
                size="small"
                orientation="horizontal"
                {...getOverrideProps(overrides, "Divider451211475")}
              ></Divider>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 157451211476")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 156451211477")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="600"
                    color="rgba(36,83,101,1)"
                    lineHeight="30px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Mais ..."
                    {...getOverrideProps(overrides, "label451211478")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(13,26,38,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children=" nos placards, "
                    {...getOverrideProps(overrides, "mantraLabel451211479")}
                  ></Text>
                </Flex>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="garages, caves, tiroirs et étagères sont remplis d’objets inutiles qui nous encombrent "
                  {...getOverrideProps(overrides, "mantraLabel451211480")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="20px"
              direction="column"
              width="250.67px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame3")}
            >
              <Image
                width="unset"
                height="150px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/mission_page/ speech_bubble.jpg"}`}
                {...getOverrideProps(overrides, "image 6")}
              ></Image>
              <Divider
                width="unset"
                height="1px"
                shrink="0"
                alignSelf="stretch"
                size="small"
                orientation="horizontal"
                {...getOverrideProps(overrides, "Divider451211483")}
              ></Divider>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 157451211484")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 156451211485")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="600"
                    color="rgba(36,83,101,1)"
                    lineHeight="30px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Et ..."
                    {...getOverrideProps(overrides, "label451211486")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(13,26,38,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children=" nous avons "
                    {...getOverrideProps(overrides, "mantraLabel451211487")}
                  ></Text>
                </Flex>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="toujours la bonne excuse, même si cette situation nous exaspère quotidiennement "
                  {...getOverrideProps(overrides, "mantraLabel451211488")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="60px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 60px 0px 60px"
            {...getOverrideProps(overrides, "Section2")}
          >
            <Flex
              gap="20px"
              direction="column"
              width="406px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame1451211490")}
            >
              <Image
                width="unset"
                height="150px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/mission_page/problem_occurs.jpg"}`}
                {...getOverrideProps(overrides, "image 9")}
              ></Image>
              <Divider
                width="unset"
                height="1px"
                shrink="0"
                alignSelf="stretch"
                size="small"
                orientation="horizontal"
                {...getOverrideProps(overrides, "Divider451211492")}
              ></Divider>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 157451211493")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 156451211494")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="600"
                    color="rgba(36,83,101,1)"
                    lineHeight="30px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="De plus ..."
                    {...getOverrideProps(overrides, "label451211495")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(13,26,38,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children=" si j’ai besoin de faire jouer la"
                    {...getOverrideProps(overrides, "mantraLabel451211496")}
                  ></Text>
                </Flex>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="garantie car mon objet est tombé en panne ou si j’ai un sinistre …"
                  {...getOverrideProps(overrides, "mantraLabel451211497")}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="20px"
              direction="column"
              width="406px"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(220,222,224,1)"
              borderRadius="2px"
              padding="19px 19px 19px 19px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame2451211498")}
            >
              <Image
                width="unset"
                height="150px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src={`${hostname}${"/resources/images/ui/mission_page/search_ticket.jpg"}`}
                {...getOverrideProps(overrides, "image 8")}
              ></Image>
              <Divider
                width="unset"
                height="1px"
                shrink="0"
                alignSelf="stretch"
                size="small"
                orientation="horizontal"
                {...getOverrideProps(overrides, "Divider451211500")}
              ></Divider>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 157451211501")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 156451211502")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="600"
                    color="rgba(36,83,101,1)"
                    lineHeight="30px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Je dois ..."
                    {...getOverrideProps(overrides, "label451211503")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(13,26,38,1)"
                    lineHeight="24px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children=" retrouver mes preuves d’achats,"
                    {...getOverrideProps(overrides, "mantraLabel451211504")}
                  ></Text>
                </Flex>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(13,26,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="que j’espère ne pas avoir jettées, ce qui est un véritable défi"
                  {...getOverrideProps(overrides, "mantraLabel451211505")}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="30px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 0px 40px 0px"
          backgroundColor="rgba(59,135,165,1)"
          {...getOverrideProps(overrides, "HowSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(255,255,255,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Oobjo : Comment ça marche ?"
            {...getOverrideProps(overrides, "label451211507")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 60px 30px 60px"
          backgroundColor="rgba(59,135,165,1)"
          {...getOverrideProps(overrides, "WhatSection")}
        >
          <Flex
            gap="50px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "functionSection")}
          >
            <Flex
              gap="10px"
              direction="column"
              width="257.33px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="2px"
              padding="10px 10px 10px 10px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "SubSection1")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 149451211511")}
              >
                <Image
                  width="50px"
                  height="50.57px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image 1")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="800"
                  color="rgba(48,64,80,1)"
                  lineHeight="20px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Oobjo centralise mes preuves d’achats :"
                  {...getOverrideProps(overrides, "label451211513")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="21px"
                textAlign="justify"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Plus de tickets perdus pour réaliser un échange ou faire jouer la garantie. Je peux même transmettre la preuve d’achat à mes proche lorsque j’offre un cadeau !"
                {...getOverrideProps(
                  overrides,
                  "Plus de tickets perdus pour r\u00E9aliser un \u00E9change ou faire jouer la garantie. Je peux m\u00EAme transmettre la preuve d\u2019achat \u00E0 mes proche lorsque j\u2019offre un cadeau !"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="257.33px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="2px"
              padding="10px 10px 10px 10px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "SubSection2")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 149451211516")}
              >
                <Image
                  width="50px"
                  height="50.57px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image 2")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="800"
                  color="rgba(48,64,80,1)"
                  lineHeight="20px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Oobjo met mes services à portée de main :"
                  {...getOverrideProps(overrides, "label451211518")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="21px"
                textAlign="justify"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Lire la notice de mes objets, trouver un réparateur, joindre les services clients, générer et publier des annonces en quelques clics sur mes sites favorits !"
                {...getOverrideProps(
                  overrides,
                  "Lire la notice de mes objets, trouver un r\u00E9parateur, joindre les services clients, g\u00E9n\u00E9rer et publier des annonces en quelques clics sur mes sites favorits !"
                )}
              ></Text>
            </Flex>
            <Flex
              gap="10px"
              direction="column"
              width="257.33px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              borderRadius="2px"
              padding="10px 10px 10px 10px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "SubSection3")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 149451211521")}
              >
                <Image
                  width="50px"
                  height="50.57px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image 3")}
                ></Image>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="800"
                  color="rgba(48,64,80,1)"
                  lineHeight="20px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Oobjo augmente mon pouvoir d’achat :"
                  {...getOverrideProps(overrides, "label451211523")}
                ></Text>
              </Flex>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="21px"
                textAlign="justify"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Lorsque je vends un objet d’occasion Oobjo me permet d’obtenir un chèque cadeau à dépenser dans l’enseigne d’origine ! Découvrez cette possibilité très bientôt !"
                {...getOverrideProps(
                  overrides,
                  "Lorsque je vends un objet d\u2019occasion Oobjo me permet d\u2019obtenir un ch\u00E8que cadeau \u00E0 d\u00E9penser dans l\u2019enseigne d\u2019origine ! D\u00E9couvrez cette possibilit\u00E9 tr\u00E8s bient\u00F4t !"
                )}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="44px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 0px 40px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ValueSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(59,135,165,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="La valeur ajoutée d’Oobjo"
            {...getOverrideProps(overrides, "label451211526")}
          ></Text>
          <Flex
            gap="125px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 162")}
          >
            <Flex
              gap="50px"
              direction="column"
              width="340px"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 160")}
            >
              <View
                width="300px"
                height="300px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 159")}
              >
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="196px"
                  left="196px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(174,179,183,1)"
                  {...getOverrideProps(overrides, "Bubble9")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 18px - 0.07px)"
                    left="calc(50% - 36.5px - 0px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Recondition-&#xA;-nement"
                    {...getOverrideProps(overrides, "Recondition- -nement")}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="214px"
                  left="107px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(36,83,101,1)"
                  {...getOverrideProps(overrides, "Bubble8")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="21px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 10.5px - -0.43px)"
                    left="calc(50% - 33.5px - -1px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Assureurs"
                    {...getOverrideProps(overrides, "Assureurs")}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="196px"
                  left="18px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(174,179,183,1)"
                  {...getOverrideProps(overrides, "Bubble7")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 9px - 0.07px)"
                    left="calc(50% - 36px - -0.5px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Associations"
                    {...getOverrideProps(overrides, "Associations")}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="107px"
                  left="215px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(36,83,101,1)"
                  {...getOverrideProps(overrides, "Bubble6")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 18px - -0.5px)"
                    left="calc(50% - 34px - -0.5px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Plateformes&#xA;de rachat"
                    {...getOverrideProps(overrides, "Plateformes de rachat")}
                  ></Text>
                </View>
                <View
                  width="102px"
                  height="51px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="125.43px"
                  left="87px"
                  borderRadius="167px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(255,255,255,1)"
                  {...getOverrideProps(overrides, "Bubble5")}
                >
                  <Image
                    width="100px"
                    height="40px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="3px"
                    left="13.5px"
                    padding="0px 0px 0px 0px"
                    objectFit="cover"
                    src={`${hostname}${"/resources/images/logo192.png"}`}
                    {...getOverrideProps(overrides, "logoImg451211539")}
                  ></Image>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="107px"
                  left="0px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(36,83,101,1)"
                  {...getOverrideProps(overrides, "Bubble4")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 9px - -0.5px)"
                    left="calc(50% - 34.5px - 0px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Réparateurs"
                    {...getOverrideProps(overrides, "R\u00E9parateurs")}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="18px"
                  left="196px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(174,179,183,1)"
                  {...getOverrideProps(overrides, "Bubble3")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 18px - 0.07px)"
                    left="calc(50% - 40px - -0.5px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Plateforme&#xA;collaboratives"
                    {...getOverrideProps(
                      overrides,
                      "Plateforme collaboratives"
                    )}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="107px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(36,83,101,1)"
                  {...getOverrideProps(overrides, "Bubble2")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 9px - 0.07px)"
                    left="calc(50% - 32px - -0.5px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Utilisateurs"
                    {...getOverrideProps(overrides, "Utilisateurs")}
                  ></Text>
                </View>
                <View
                  width="85px"
                  height="85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="18px"
                  left="18px"
                  borderRadius="100px"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(174,179,183,1)"
                  {...getOverrideProps(overrides, "Bubble1")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="12px"
                    fontWeight="400"
                    color="rgba(255,255,255,1)"
                    lineHeight="18px"
                    textAlign="center"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    position="absolute"
                    top="calc(50% - 9px - 0.07px)"
                    left="calc(50% - 36.5px - 0px)"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Distributeurs"
                    {...getOverrideProps(overrides, "Distributeurs")}
                  ></Text>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="34.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="81px"
                  left="70.5px"
                  transformOrigin="top left"
                  transform="rotate(-45deg)"
                  {...getOverrideProps(overrides, "Arrow8")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 19.798999786376953,
                      height: 19.798828125,
                    }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(174,179,183,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="34.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211549")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(174,179,183,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211550")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="35.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="157.43px"
                  left="62.5px"
                  transformOrigin="top left"
                  transform="rotate(-90deg)"
                  {...getOverrideProps(overrides, "Arrow7")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(36,83,101,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="35.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211552")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(36,83,101,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211553")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="34.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="230.42px"
                  left="80.51px"
                  transformOrigin="top left"
                  transform="rotate(-125deg)"
                  {...getOverrideProps(overrides, "Arrow6")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 19.498210906982422,
                      height: 19.498291015625,
                    }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(174,179,183,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="34.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211555")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(174,179,183,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211556")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="35.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="236.28px"
                  left="157.5px"
                  transformOrigin="top left"
                  transform="rotate(180deg)"
                  {...getOverrideProps(overrides, "Arrow5")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(36,83,101,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="35.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211558")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(36,83,101,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211559")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="34.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="218.07px"
                  left="228.04px"
                  transformOrigin="top left"
                  transform="rotate(135deg)"
                  {...getOverrideProps(overrides, "Arrow4")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 19.798995971679688,
                      height: 19.798828125,
                    }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(174,179,183,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="34.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211561")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(174,179,183,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211562")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="35.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="143.35px"
                  left="237.43px"
                  transformOrigin="top left"
                  transform="rotate(90deg)"
                  {...getOverrideProps(overrides, "Arrow3")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(36,83,101,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="35.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211564")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(36,83,101,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211565")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="34.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="72.43px"
                  left="219.14px"
                  transformOrigin="top left"
                  transform="rotate(45deg)"
                  {...getOverrideProps(overrides, "Arrow2")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 19.798988342285156,
                      height: 19.799072265625,
                    }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(174,179,183,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="34.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211567")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(174,179,183,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211568")}
                  ></View>
                </View>
                <View
                  padding="0px 0px 0px 0px"
                  width="14px"
                  height="34.85px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="64.43px"
                  left="143.5px"
                  {...getOverrideProps(overrides, "Arrow1")}
                >
                  <Icon
                    width="14px"
                    height="14px"
                    viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
                    paths={[
                      {
                        d: "M6.13398 1.5C6.51888 0.833334 7.48113 0.833333 7.86603 1.5L12.1962 9C12.5811 9.66667 12.0999 10.5 11.3301 10.5L2.66987 10.5C1.90007 10.5 1.41895 9.66667 1.80385 9L6.13398 1.5Z",
                        fill: "rgba(36,83,101,1)",
                        fillRule: "nonzero",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="34.85px"
                    left="14px"
                    transformOrigin="top left"
                    transform="rotate(180deg)"
                    {...getOverrideProps(overrides, "Polygon 1451211570")}
                  ></Icon>
                  <View
                    width="2px"
                    height="29px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="6px"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(36,83,101,1)"
                    {...getOverrideProps(overrides, "Rectangle 1451211571")}
                  ></View>
                </View>
              </View>
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="36px"
                textAlign="justify"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="213px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="… et réduire les impacts de nos objets en accompagnant les utilisateurs dans l’adoption d’un mode de consommation durable."
                {...getOverrideProps(overrides, "mantraLabel451211572")}
              ></Text>
            </Flex>
            <Flex
              gap="108px"
              direction="column"
              width="340px"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 161")}
            >
              <Text
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="36px"
                textAlign="justify"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="157px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Oobjo est au cœur de l’écosystème des acteurs intervenants dans la vie des objets pour créer entre eux une relation vertueuse et..."
                {...getOverrideProps(overrides, "mantraLabel451211574")}
              ></Text>
              <View
                width="300px"
                height="291px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "3BubbleFrame")}
              >
                <Icon
                  width="187px"
                  height="187px"
                  viewBox={{ minX: 0, minY: 0, width: 187, height: 187 }}
                  paths={[
                    {
                      d: "M187 93.5C187 145.139 145.139 187 93.5 187C41.8614 187 0 145.139 0 93.5C0 41.8614 41.8614 0 93.5 0C145.139 0 187 41.8614 187 93.5Z",
                      fill: "rgba(59,135,165,0.85)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="0px"
                  {...getOverrideProps(overrides, "Ellipse 1")}
                ></Icon>
                <Icon
                  width="187px"
                  height="187px"
                  viewBox={{ minX: 0, minY: 0, width: 187, height: 187 }}
                  paths={[
                    {
                      d: "M187 93.5C187 145.139 145.139 187 93.5 187C41.8614 187 0 145.139 0 93.5C0 41.8614 41.8614 0 93.5 0C145.139 0 187 41.8614 187 93.5Z",
                      fill: "rgba(59,135,165,0.65)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0px"
                  left="113px"
                  {...getOverrideProps(overrides, "Ellipse 2")}
                ></Icon>
                <Icon
                  width="188px"
                  height="187px"
                  viewBox={{ minX: 0, minY: 0, width: 188, height: 187 }}
                  paths={[
                    {
                      d: "M188 93.5C188 145.139 145.915 187 94 187C42.0852 187 0 145.139 0 93.5C0 41.8614 42.0852 0 94 0C145.915 0 188 41.8614 188 93.5Z",
                      fill: "rgba(59,135,165,0.75)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="104px"
                  left="56px"
                  {...getOverrideProps(overrides, "Ellipse 3")}
                ></Icon>
                <Text
                  fontFamily="Inter"
                  fontSize="10px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="15px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="90px"
                  height="74px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="209px"
                  left="104px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Préserver les ressources et  réduire l’impacte de notre mode de vie"
                  {...getOverrideProps(
                    overrides,
                    "Pr\u00E9server les ressources et r\u00E9duire l\u2019impacte de notre mode de vie"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="800"
                  color="rgba(255,255,255,1)"
                  lineHeight="18px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="68px"
                  height="19px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="24px"
                  left="198px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="ECONOMIE"
                  {...getOverrideProps(overrides, "ECONOMIE")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="10px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="15px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="90px"
                  height="69px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="43px"
                  left="187px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Créer des richesses et améliorer les conditions de vie matérielles"
                  {...getOverrideProps(
                    overrides,
                    "Cr\u00E9er des richesses et am\u00E9liorer les conditions de vie mat\u00E9rielles"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="800"
                  color="rgba(255,255,255,1)"
                  lineHeight="18px"
                  textAlign="justify"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="110px"
                  height="18px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="191px"
                  left="94px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="ENVIRONNEMENT"
                  {...getOverrideProps(overrides, "ENVIRONNEMENT")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="700"
                  color="rgba(255,255,255,1)"
                  lineHeight="19.5px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="57px"
                  height="15px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="149px"
                  left="78px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Vivable"
                  {...getOverrideProps(overrides, "Vivable")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="700"
                  color="rgba(255,255,255,1)"
                  lineHeight="19.5px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="61px"
                  height="15px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="67px"
                  left="119px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Equitable"
                  {...getOverrideProps(overrides, "Equitable")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="13px"
                  fontWeight="700"
                  color="rgba(255,255,255,1)"
                  lineHeight="19.5px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="48px"
                  height="15px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="149px"
                  left="172px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Viable"
                  {...getOverrideProps(overrides, "Viable")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="10px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="15px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="90px"
                  height="87px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="43px"
                  left="17px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Favoriser et faciliter les interactions entre les acteurs de l’écosystème"
                  {...getOverrideProps(
                    overrides,
                    "Favoriser et faciliter les interactions entre les acteurs de l\u2019\u00E9cosyst\u00E8me"
                  )}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="12px"
                  fontWeight="800"
                  color="rgba(255,255,255,1)"
                  lineHeight="18px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="55px"
                  height="19px"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="24px"
                  left="35px"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="SOCIETE"
                  {...getOverrideProps(overrides, "SOCIETE")}
                ></Text>
                <Icon
                  width="71px"
                  height="63.76px"
                  viewBox={{
                    minX: 0,
                    minY: 0,
                    width: 71.00003051757812,
                    height: 63.755126953125,
                  }}
                  paths={[
                    {
                      d: "M0 6.75525C3.5 31.2551 17 51.7551 35.4999 63.7552C53 52.7551 66.5 30.7551 71 6.75535C46.9999 -2.74489 18.4999 -1.74478 0 6.75525Z",
                      fill: "rgba(255,255,255,1)",
                      fillRule: "nonzero",
                    },
                  ]}
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="104.24px"
                  left="114.5px"
                  {...getOverrideProps(overrides, "Vector 1")}
                ></Icon>
                <Image
                  width="56px"
                  height="22.17px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="115px"
                  left="121px"
                  borderRadius="25px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={`${hostname}${"/resources/images/logo192.png"}`}
                  {...getOverrideProps(overrides, "logoImg451211589")}
                ></Image>
              </View>
            </Flex>
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider451211590")}
        ></Divider>
        <Flex
          gap="40px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="40px 30px 40px 30px"
          backgroundColor="rgba(59,135,165,1)"
          {...getOverrideProps(overrides, "PartnershipSection")}
        >
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(255,255,255,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Devenir un partenaire d’Oobjo"
            {...getOverrideProps(overrides, "label451211592")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="48px"
            fontWeight="300"
            color="rgba(255,255,255,1)"
            lineHeight="60px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Je suis :"
            {...getOverrideProps(overrides, "label451211593")}
          ></Text>
          <Flex
            gap="30px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 30px"
            {...getOverrideProps(overrides, "Frame 163")}
          >
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner1")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 164")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Une enseigne&#xA;physique ou digitale"
                  {...getOverrideProps(overrides, "label451211597")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211598")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="569px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• J’accompagne mes clients avec des services post-achats&#xA;• Mes clients deviennent mes ambassadeurs et diffusent une image  durable et responsable de ma marque&#xA;• J’accentue la rétention client et obtiens de nouveaux clients qualifiés"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 J\u2019accompagne mes clients avec des services post-achats \u2022 Mes clients deviennent mes ambassadeurs et diffusent une image durable et responsable de ma marque \u2022 J\u2019accentue la r\u00E9tention client et obtiens de nouveaux clients qualifi\u00E9s"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner2")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 165451211601")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Une plateforme de&#xA;vente d’occasion"
                  {...getOverrideProps(overrides, "label451211602")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211603")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• Je permets à mes clients d’accéder à des services post-achats&#xA;• Je dispose d’un flux d’annonces complémentaire&#xA;• Je diffuse une image durable et responsable de ma marque"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 Je permets \u00E0 mes clients d\u2019acc\u00E9der \u00E0 des services post-achats \u2022 Je dispose d\u2019un flux d\u2019annonces compl\u00E9mentaire \u2022 Je diffuse une image durable et responsable de ma marque"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner3")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 165451211606")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Un réparateur ou &#xA;restaurateur d’objets"
                  {...getOverrideProps(overrides, "label451211607")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211608")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• Je suis mis en relation avec des clients qualifiés dans mon secteur d’intervention&#xA;• J’acquière de nouveaux clients et augmente mon volume d’affaires&#xA;• J’installe une relation de confiance avec mes clients"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 Je suis mis en relation avec des clients qualifi\u00E9s dans mon secteur d\u2019intervention \u2022 J\u2019acqui\u00E8re de nouveaux clients et augmente mon volume d\u2019affaires \u2022 J\u2019installe une relation de confiance avec mes clients"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner4")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 165451211611")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Une enseigne de&#xA; reconditionnement"
                  {...getOverrideProps(overrides, "label451211612")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211613")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="557px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• Je dispose d’un flux d’acquisition d’objets complémentaire&#xA;• Je diffuse une image durable et responsable de ma marque&#xA;• J’accentue le rétention clients et j’obtiens de nouveaux clients qualifiés"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 Je dispose d\u2019un flux d\u2019acquisition d\u2019objets compl\u00E9mentaire \u2022 Je diffuse une image durable et responsable de ma marque \u2022 J\u2019accentue le r\u00E9tention clients et j\u2019obtiens de nouveaux clients qualifi\u00E9s"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner5")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 165451211616")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Un assureur"
                  {...getOverrideProps(overrides, "label451211617")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211618")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• Je propose de nouveaux produits d’assurance ou micro assurance au bon moment à des clients qualifiés&#xA;• J’identifie précisément les besoins de mes clients&#xA;• J’acquière de nouveaux clients et augmente mon volume d’affaires"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 Je propose de nouveaux produits d\u2019assurance ou micro assurance au bon moment \u00E0 des clients qualifi\u00E9s \u2022 J\u2019identifie pr\u00E9cis\u00E9ment les besoins de mes clients \u2022 J\u2019acqui\u00E8re de nouveaux clients et augmente mon volume d\u2019affaires"
                  )}
                ></Text>
              </Flex>
            </Flex>
            <Flex
              gap="30px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              border="1px SOLID rgba(255,255,255,1)"
              borderRadius="10px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Partner6")}
            >
              <Flex
                gap="10px"
                direction="column"
                width="280px"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="10px 10px 10px 10px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 165451211621")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="20px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  textTransform="uppercase"
                  lineHeight="25px"
                  textAlign="center"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Une association"
                  {...getOverrideProps(overrides, "label451211622")}
                ></Text>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="10px 0px 10px 0px"
                {...getOverrideProps(overrides, "TextSection451211623")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="400"
                  color="rgba(255,255,255,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="• Je suis identifiable par des donateurs et j’augmente ma visibilité&#xA;• J’augmente le volume de dons reçus en nature&#xA;• J’augmente mon impact social, sociétal et environnemental"
                  {...getOverrideProps(
                    overrides,
                    "\u2022 Je suis identifiable par des donateurs et j\u2019augmente ma visibilit\u00E9 \u2022 J\u2019augmente le volume de dons re\u00E7us en nature \u2022 J\u2019augmente mon impact social, soci\u00E9tal et environnemental"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Télécharger le Pitch Deck d’Oobjo"
            onClick={() => {
              pitchOobjoOnClick();
            }}
            {...getOverrideProps(overrides, "PitchOobjo")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
