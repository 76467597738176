import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { UserAccountPage } from '../ui-components';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';
import { DataStore, Auth } from 'aws-amplify';
import {User} from '../models';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';

class UserAccount extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
            user: undefined,
            showModalWaiting: false
        };
    }
    

    componentDidMount() {
        DataStore.query(User, this.props.authUser.username).then(
            (user)=> {
                this.setState({
                    user:user
                });
            }
            ); 
    }

    async saveUser() {
        this.setState({showModalWaiting:true});
        await DataStore.save(this.state.user);
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.componentDidMount(); // force to update Datastore user version
            this.navigate(-1);
        }, 500);

    }

    async signOut() {
        try {
            //await Auth.signOut({ global: true });
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <UserAccountPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    LoginLabel: { children: this.props.authUser.attributes['email'] },
                    PseudonymTextField: {
                        defaultValue: this.state.user?.pseudonym,
                        onBlur: (event) => {
                            this.setState({
                                user:User.copyOf(this.state.user, updated => {
                                    updated.pseudonym = event.target.value
                                })
                            })
                        },
                    },
                    SaveProfileButton: { onClick: (e) => {this.saveUser()}},
                    LogOutButton: { onClick: (e) => {
                        this.navigate("/", { replace: false });
                        DataStore.clear().then(() => {
                            this.signOut().then(() => {
                                window.location.assign('/');
                        });
                    });
                    } },
                }}
                />
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(UserAccount);