
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate
} from "react-router-dom";
import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { UserNavigationBar, AddReceiptPage, AllReceiptsPage } from './ui-components';
import { Authenticator, Flex } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import SecondHandItemAd from './ui/SecondHandItemAd';
import ItemDetails from './ui/ItemDetails';
import AllItems from './ui/AllItems';
import AllItemAds from './ui/AllItemAds';
import AddItem from './ui/AddItem';
import AllReceipts from './ui/AllReceipts';
import AddReceipt from './ui/AddReceipt';
import AddReceiptItem from './ui/AddReceiptItem';
import ReceiptDetails from "./ui/ReceiptDetails";
import Home from './ui/Home';
import UserAccount from './ui/UserAccount';
import CompanyAccount from "./ui/CompanyAccount";
import Landing from './ui/Landing';
import Mission from './ui/Mission';
import UserNavBar from './ui/UserNavBar';
import CookiesAndCGU from "./ui/CookiesAndCGU";
import { Auth, Hub, Logger } from 'aws-amplify';
import { User } from './models';
import { LegalsPage } from './ui-components';
import { studioTheme } from './ui-components';
import { FooterBar } from './ui-components';




const logger = new Logger('My-Logger');
const LAST_CGU_DATE = '2023-02-16T00:00:00';
const LAST_COOKIES_POLICY_DATE = '2023-02-16T00:00:00';
let currentUser = undefined;
let datastoreReady = false;

function App(params) {
    let navigate = useNavigate();
    async function handleSubmit(event) {
        event.preventDefault();
        navigate("/", { replace: true });
    }


    // Create listener
    if (!datastoreReady) {
        const listener = Hub.listen("datastore", async hubData => {
            const { event, data } = hubData.payload;
            //console.log(event)
            if (event === "ready") {
                // do something here once the data is synced from the cloud
                datastoreReady = true;
                if (user) {
                    DataStore.query(User, user.username).then(
                        (loadedUser) => {
                            if ((!loadedUser) || new Date(loadedUser.cguAcceptedDateTime) < new Date(LAST_CGU_DATE)
                                || new Date(loadedUser.cookiesPolicyAcceptedDateTime) < new Date(LAST_COOKIES_POLICY_DATE)) {
                                console.log("goto /cookiesandcgu page" + loadedUser);
                                navigate("/cookiesandcgu");
                            }
                            else {
                                loadedUser = User.copyOf(loadedUser, updated => {
                                    updated.LastConnectionDateTime = new Date().toISOString();
                                })
                                DataStore.save(loadedUser);
                            }
                            currentUser = loadedUser;
                        }
                    );
                }
                listener();
            }
        });
    }
    //listener();



    //const {auth} = useAuthenticator((context) => [context.authStatus]);

    const { user, signOut } = useAuthenticator((context) => {
        if ( (context.user) && (!datastoreReady) )
        {
            try {
                DataStore.start();
                console.log("Datastore Force Start");
            } catch (error) {
                console.log("Try to force starting Datastore but already started");
            }

        }
        return [context.user]
    });

    return (
        <div style={{backgroundColor: studioTheme.tokens.colors.background.tertiary,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column"
        }} >
            <Routes >
                <Route path="/home" element={
                    <Authenticator>
                        <Home authUser={user} ></Home>
                    </Authenticator>
                }>
                </Route>
                <Route path="/useraccount" element={
                    <Authenticator>
                        <UserNavBar authUser={user} />
                        <UserAccount authUser={user}></UserAccount>
                    </Authenticator>
                }>
                </Route>
                <Route path="/companyaccount" element={
                    <Authenticator>
                        <UserNavBar authUser={user} />
                        <CompanyAccount authUser={user}></CompanyAccount>
                    </Authenticator>
                }>
                </Route>
                <Route path="/additem" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <AddItem></ AddItem>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/allitems" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <AllItems></ AllItems>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/allitemads" element={
                    <Authenticator>
                        {({ signOut, user }) => (
                            <>
                                <UserNavBar authUser={user} />
                                <AllItemAds></ AllItemAds>
                            </>
                        )}
                    </Authenticator>
                }>
                </Route>

                <Route path="/itemdetails/:id" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <ItemDetails user={user}></ItemDetails>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/itemad/:id" element={
                    <div>
                        <UserNavBar authUser={user} />
                        <SecondHandItemAd authUser={user}/>
                    </div>
                }>
                </Route>
                <Route path="/noitems" element={
                    <div>
                        <UserNavBar authUser={user} />
                        Cette URL ne correspond pas à l'annonce d'un objet
                    </div>
                }>
                </Route>
                <Route path="/addreceipt" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <AddReceipt></AddReceipt>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/addreceiptitem" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <AddReceiptItem></AddReceiptItem>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/allreceipts" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <AllReceipts></AllReceipts>
                        </>
                    </Authenticator>
                }>
                </Route>

                <Route path="/receiptdetails/:id" element={
                    <Authenticator>
                        <>
                            <UserNavBar authUser={user} />
                            <ReceiptDetails></ReceiptDetails>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/cookiesandcgu" element={
                    <Authenticator>
                        <>
                            <CookiesAndCGU authUser={user}></CookiesAndCGU>
                        </>
                    </Authenticator>
                }>
                </Route>
                <Route path="/legals" element={
                    <>
                        <UserNavBar authUser={user} />
                        <LegalsPage 
                        overrides={{
                            BackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                        }}
                        ></LegalsPage>
                    </>
                }>
                </Route>
                <Route path="/" element={
                    <div>
                        <UserNavBar authUser={user} />
                        <Landing hostname="" authUser={user}/>
                    </div>
                }>
                </Route>
                <Route path="/mission" element={
                    <div>
                        <UserNavBar authUser={user} />
                        <Mission hostname="" authUser={user}/>
                    </div>
                }>
                </Route>

            </Routes>
            <FooterBar
                
            style={{}}
                
                marginTop= "auto"                       
                            />

        </div>

    );
}
//}



export default App;