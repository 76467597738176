import React from 'react';
import withRouter from './withRouter';
import { ReceiptDetailsPage } from "../ui-components";

import { DataStore } from '@aws-amplify/datastore';
import { Company, Item, ItemAd, Receipt } from '../models';

import { updateS3SignedUrls, downloadTextFromS3Uri, removeFileFromS3Uri} from "../data/s3"
import { deleteReceiptFromDS } from "../data/datastore"
import { elapsedMonths } from '../tools/date';
import { evaluateUsedPrice, giftCertificateAmount } from '../tools/price';

import omit from 'object.omit'
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';
//import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

class ReceiptDetails extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
            receiptId: props.params.id,
            receipt: undefined,
            company: undefined,
            companyLogoS3Uri: undefined,
            receiptS3Uri: undefined,

        };
    }

    async fetchReceipt() {
        const receipt = await DataStore.query(Receipt, this.state.receiptId);
        const company = await receipt.company
        return { receipt, company };
    }

    async deleteReceipt(){
        await deleteReceiptFromDS(this.state.receipt)
        await this.navigate(`/allreceipts`, { replace: false })
    }

    componentDidMount() {
        this.fetchReceipt().then(({ company, receipt }) => {
            this.setState({
                receipt: receipt,
                company: company,
            });
            //updateS3SignedUrls(company.companyLogoFileS3Uri, this, "companyLogoS3Uri")
            updateS3SignedUrls(receipt.receiptFileS3Uri, this, "receiptS3Uri");
            
        });
    }

    render() {
        //console.log(this.state.receiptS3Uri);
        var rc = undefined;
        if (this.state?.receipt?.receiptFileS3Uri){
            if (this.state.receipt.receiptFileS3Uri.split('.').pop()=='pdf'){
                rc = <iframe width="100%" height="560px" src={this.state.receiptS3Uri}></iframe>
            } else {
                rc = <img src={this.state.receiptS3Uri} width="100%" height="unset" ></img>
            }
        }
        return (
            <ReceiptDetailsPage hostname="" receipt={this.state.receipt}
                receiptDisplay={
                    // <DocViewer 
                    // sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    // width="100%"
                    // height="100%"
                    // documents={["https://www.ecam.fr/wp-content/uploads/sites/3/2016/06/Exemple-fichier-PDF-1.pdf"]} pluginRenderers={DocViewerRenderers} />
                    rc
                }
                overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    //CompanyImage: { objectFit: "contain", backgroundColor: "white", src: this.state.companyLogoS3Uri ?? DEFAULT_BCKGND_IMG_URL },
                    DeleteReceiptButton: { onClick: () => { window.confirm( "Etes vous sur de vouloir supprimer ce ticket et les objets associés ?") && this.deleteReceipt() }},
                    ReceiptButton: { onClick: () => { window.open(this.state.receiptS3Uri) } },
                    BackButton: {
                        onClick: () => {
                                    this.navigate(-1, { replace: false });
                                    
                        }
                    },
                }} />
        );
    }
}

export default withRouter(ReceiptDetails);