/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function CompanyHomePage(props) {
  const { hostname = "https://dev.oobjo.com", overrides, ...rest } = props;
  const editProfileButtonOnClick = useNavigateAction({
    type: "url",
    url: "/companyaccount",
  });
  const backButtonOnClick = useNavigateAction({ type: "url", url: "/" });
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(239,240,240,1)"
      {...getOverrideProps(overrides, "CompanyHomePage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="400px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "AutoLayout")}
      >
        <Flex
          gap="20px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="20px 10px 20px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "DashboardSection")}
        >
          <Image
            width="unset"
            height="253px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={`${hostname}${"/resources/images/ui/company_home_page/preview_dashboard.jpg"}`}
            {...getOverrideProps(overrides, "PreviewDashboardImage")}
          ></Image>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="800"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Bientôt, retrouvez l’interface d’administration de votre entreprise"
            {...getOverrideProps(overrides, "PreviewDashboardTitleLabel")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Dans un premier temps, personnalisez les informations de votre entreprise en cliquant sur 'Personnaliser mon profile&quot;"
            {...getOverrideProps(overrides, "ButtonDescriptionLabel")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "ButtonsSection")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="default"
            children="Personnaliser le profile de l’entreprise"
            onClick={() => {
              editProfileButtonOnClick();
            }}
            {...getOverrideProps(overrides, "EditProfileButton")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Retour"
            onClick={() => {
              backButtonOnClick();
            }}
            {...getOverrideProps(overrides, "BackButton")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
