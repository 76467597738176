import React from 'react';
import withRouter from './withRouter';
import { UserNavigationBar } from "../ui-components";
import { DataStore } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import { Hub } from 'aws-amplify';




class UserNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.navigate = props.navigate;
        this.state = {
        };
    }

    // keep in case
    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log("nextProps " + String(nextProps.user));
    //     console.log("nextState " + String(nextState.user));
    //     if (nextProps.user !== this.props.user) {
    //         this.setState({ user: nextProps?.myuser });
    //     }
    //     return (
    //         Object.entries(nextProps).some(([key, value]) => value !== this.props[key]) ||
    //         Object.entries(nextState).some(([key, value]) => value !== this.state[key])
    //     )
    // }

    async signOut() {
        try {
            //await Auth.signOut({ global: true });
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    isLoggedIn() {
        return this.props?.authUser?.username ? true : false;
    }

    render() {
        return (
            <div>
                <UserNavigationBar overrides={{
                    HomeButton: { display: this.isLoggedIn() ? "unset" : "none" },
                    LogoutButton: {
                        display: this.isLoggedIn() ? "unset" : "none",
                        onClick: (e) => {
                            this.signOut().then(() => {
                                DataStore.clear();
                                //this.navigate("/", { replace: false });
                                //window.location.reload(false);
                                window.location.replace('/');
                            });
                        }
                    },
                    LoginButton: { display: this.isLoggedIn() ? "none" : "unset" },
                    AccountLinkLabel: { 
                        children:this.props?.authUser?.attributes?.email?.charAt(0).toUpperCase(),
                    },
                    AccountLink: { 
                        display: this.isLoggedIn() ? "unset" : "none" ,
                        onClick: (e) => {
                            const isCompany = this.props?.authUser?.signInUserSession?.accessToken?.payload['cognito:groups'] == "company";
                            if (isCompany)
                                this.navigate("/companyaccount", { replace: false });
                            else
                                this.navigate("/useraccount", { replace: false });
                        }
                    },
                }} />
            </div>
        );
    }
}

export default withRouter(UserNavBar);