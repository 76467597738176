import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { AddReceiptItemPage } from '../ui-components';
import { Image } from '@aws-amplify/ui-react';
import { Company, Item, ItemAd, Receipt } from '../models';
import {convertBase64} from '../tools/base64'
import { Auth } from 'aws-amplify';
import { apiOobjoEndpoint } from '../tools/api';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import { DataStore } from 'aws-amplify';
import imageCompression from 'browser-image-compression';
import * as config from "../config";

class AddItem extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.receiptRef = React.createRef();
        const today = new Date().getFullYear()+'-'+("0"+(new Date().getMonth()+1)).slice(-2)+'-'+("0"+new Date().getDate()).slice(-2)
        this.state = {
            item: new Item({}),
            itemName: undefined,
            itemCompanyName: undefined,
            // set current date of itemPurchaseDate to yyyy-mm-dd format

            itemPurchaseDate: today,
            
            itemPrice: undefined,
            currency: undefined,
            receiptFileObj: undefined,
            showModalWaiting: false
        };
    }

    async saveItem() {
        this.setState({showModalWaiting:true});
        let receipt = {
            name: `Ma facture : ${this.state.itemCompanyName}`,
            companyName: this.state.itemCompanyName,
            purchaseDate: this.state.itemPurchaseDate,
            amount: this.state.itemPrice,
            currency: "€",
        }

        if (  this.state.itemName && (this.state.itemName != "")  ) {
            receipt["items"]=[
                {
                    name: this.state.itemName,
                    price: this.state.itemPrice,
                    currency: "€",
                }
            ]
        }

        if (this.state.receiptFileObj){
            let fileBlob = this.state.receiptFileObj;
            if (this.state.receiptFileObj.type.startsWith('image')) {
                fileBlob = await imageCompression(this.state.receiptFileObj, 
                    {maxWidthOrHeight: config.RECEIPT_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            }
            const [image0FileB64, image0Mime] = await convertBase64(fileBlob);
            receipt.receiptFile={mime: image0Mime, data: image0FileB64 };
        }

        const options = {
            method: 'POST',
            //mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
            },
            body: JSON.stringify(receipt)
        };
        const baseUrl = await apiOobjoEndpoint()
        const response = await fetch(new Request(`${baseUrl}/v1/receipt`, options));
        const body = await response.json();
        if (response.status != 201) {
            throw `Receipt not Created, ResponseStatus=${response.status}`;
        }
        else {
            console.log("Receipt saved finish");
        }
        //await DataStore.stop();
        //await DataStore.start();
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.navigate(-1);
        }, 0);
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <input ref={this.receiptRef} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({receiptFileObj: e.target.files && e.target.files[0]})}}       
                />
                <AddReceiptItemPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    ItemNameTitleTextField: {
                        onBlur: (event) => {
                            this.setState({itemName:event.target.value});
                        }
                    },
                    ItemCompanyTextField: {
                        onBlur: (event) => {
                            this.setState({itemCompanyName:event.target.value});
                        }
                    },
                    PurchaseDateTextField: {
                        type: "date",
                        defaultValue: this.state.itemPurchaseDate,
                        onBlur: (event) => {
                            this.setState({itemPurchaseDate:event.target.value});
                        }
                    },
                    ItemPriceTextField: {
                        type: "number",
                        onBlur: (event) => {
                            this.setState({itemPrice:event.target.value});
                        }
                    },
                    
                    ReceiptButton: { onClick: (e) => {this.receiptRef.current.click();}},
                    SaveItemButton: { onClick: (e) => {this.saveItem()}},
                }}
                />
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(AddItem);