import { Storage } from "@aws-amplify/storage";
import { Auth } from 'aws-amplify';
import awsExports from '../aws-exports';
import mime from 'mime'

export function infosFrom(s3Uri) {
    const pathname = s3Uri.replace('s3://', '/');
    const key = pathname.split('/').slice(4).join('/');
    const level = pathname.split('/')[2];
    const identityId = pathname.split('/')[3];
    let contentType = undefined;
    try {
        contentType = mime.getType(pathname.split('.').pop());
    } catch (error) {
        contentType = "application/octet-stream";
    }
    return [key, level, identityId, contentType];
}

export function updateS3SignedUrls(s3Uri, reactComponent, stateKey) {
    if (!stateKey) {
        if (s3Uri) {
            stateKey = s3Uri.replace(/[\W_]+/g, "");
        }
    }
    if (s3Uri && stateKey && (!(stateKey in reactComponent.state) || (reactComponent.state[stateKey] == undefined))) {
        reactComponent.state[stateKey] = null;
        const [key, level, identityId, contentType] = infosFrom(s3Uri);
        Storage.get(key, {
            level: level, // defaults to `public`
            identityId: identityId, // id of another user, if `level: protected`
            download: false, // defaults to false
            expires: 7200, // validity of the URL, in seconds. defaults to 900 (15 minutes)
            contentType: contentType // set return content type, eg "text/html"
        }).then((signedURL) => {
            reactComponent.setState({ [stateKey]: signedURL })
        })
    }

    if ((s3Uri==undefined) && stateKey && (!(stateKey in reactComponent.state) || !(reactComponent.state[stateKey] == undefined))) {
        reactComponent.setState({ [stateKey]: undefined })
    }
    return stateKey;
}

export async function downloadTextFromS3Uri(s3Uri) {
    if (s3Uri) {
        const [key, level, identityId, contentType] = infosFrom(s3Uri);
        const result = await Storage.get(key, {
            level: level, // defaults to `public`
            identityId: identityId, // id of another user, if `level: protected`
            download: true, // defaults to false
            expires: 7200, // validity of the URL, in seconds. defaults to 900 (15 minutes)
            contentType: contentType // set return content type, eg "text/html"
        });
        const text = await result.Body.text();
        return text;
    } else {
        return undefined;
    }
}

export async function downloadBinaryFromS3Uri(s3Uri) {
    if (s3Uri) {
        const [key, level, identityId, contentType] = infosFrom(s3Uri);
        const result = await Storage.get(key, {
            level: level, // defaults to `public`
            identityId: identityId, // id of another user, if `level: protected`
            download: false, // defaults to false
            expires: 7200, // validity of the URL, in seconds. defaults to 900 (15 minutes)
            contentType: contentType // set return content type, eg "text/html"
        });
        const blob = await result.Body;
        return blob;
    } else {
        return undefined;
    }
}

export async function removeFileFromS3Uri(s3Uri) {
    if (s3Uri) {
        const [key, level, identityId, contentType] = infosFrom(s3Uri);
        await Storage.remove(key, { level: level });
    }
}

export async function uploadToS3Uri(path, file, level, contentType) {
    let s3Uri = undefined;
    if (path && file && level && contentType) {
        const creds = await Auth.currentCredentials();
        let s3Path = path; // private & public
        if (level == 'public')
            s3Path = `${creds.identityId}/${path}`;
        const result = await Storage.put(s3Path, file, {
            level: level,
            contentType: contentType,
        });
        s3Uri = `s3://${awsExports.aws_user_files_s3_bucket}/${level}/${creds.identityId}/${path}`;
    } else {
        throw new Error(`uploadToS3Uri(${path}, ${file}, ${level}, ${contentType}) has wrong parameters`);
    }
    return s3Uri;
}

export function imageThumbnailS3Uri(fromS3Uri)
{
    if (fromS3Uri)
        return fromS3Uri.replace(/(\.)(?!.*\1)/, '.thumb.');
    else
        return undefined;
}