import React from 'react';
import withRouter from './withRouter';
import { MissionPage } from "../ui-components";
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';

class Mission extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.DESKTOP); 
        super(props);
        this.navigate = props.navigate;
        this.state = {
        };
    }

    isLoggedIn() {
        return this.props?.authUser?.username ? true : false;
    }
    
    render() {
        return (
            <MissionPage hostname=""/>
        );
    }
}

export default withRouter(Mission);