import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls, downloadBinaryFromS3Uri, downloadTextFromS3Uri, uploadToS3Uri } from "../data/s3"
import { CompanyAccountPage } from '../ui-components';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL} from '../tools/ui';
import { DataStore, Auth } from 'aws-amplify';
import {Company} from '../models';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from "@aws-amplify/storage";
import imageCompression from 'browser-image-compression';
import * as config from "../config"

class CompanyAccount extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE); 
        super(props);
        this.navigate = props.navigate;
        this.companyLogoImageRef = React.createRef();
        this.state = {
            company: undefined,
            companyLogoImageFileObj: undefined,
            companyLogoImageS3Uri: undefined,
            showModalWaiting: false
        };
    }
    

    componentDidMount() {
        DataStore.query(Company, this.props.authUser.username).then(
            (company)=> {
                this.setState({
                    company:company ?? new Company({id:this.props.authUser.username}),
                    companyLogoImageFileObj: undefined,
                    companyLogoImageS3Uri: undefined
                }, ()=>{
                    updateS3SignedUrls(company.companyLogoFileS3Uri, this, "companyLogoImageS3Uri")
            });                
                ;
        }
            ); 
    }

    async saveCompany() {
        this.setState({showModalWaiting:true});
        if (this.state.companyLogoImageFileObj) {
            let fileBlob = await imageCompression(this.state.companyLogoImageFileObj, 
                {maxWidthOrHeight: config.ITEM_IMAGE_THUMBNAIL_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            let logoS3Uri = await uploadToS3Uri(`company/company_logo.thumb.jpg`, fileBlob, "protected", fileBlob.type);
            fileBlob = await imageCompression(this.state.companyLogoImageFileObj, 
                {maxWidthOrHeight: config.COMPANY_LOGO_IMAGE_MAX_SIZE, fileType: 'image/jpeg', initialQuality: config.IMAGE_COMPRESS_QUALITY});
            logoS3Uri = await uploadToS3Uri(`company/company_logo.jpg`, fileBlob, "protected", fileBlob.type);
            const companyToSave = Company.copyOf(this.state.company, updated => {
                updated.companyLogoFileS3Uri = logoS3Uri
            })
            await DataStore.save(companyToSave);
        }
        else {
            await DataStore.save(this.state.company);
        }
        setTimeout(() => {
            this.setState({showModalWaiting:false});
            this.componentDidMount(); // force to update Datastore company version
           // this.navigate(-1);
        }, 500);

    }

    async signOut() {
        try {
            //await Auth.signOut({ global: true });
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <input ref={this.companyLogoImageRef} type="file" style={{display: 'none'}}
                    onChange={(e) => {this.setState({companyLogoImageFileObj: e.target.files && e.target.files[0]})}}       
                />
                <CompanyAccountPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate(-1,{ replace: false})}},
                    LoginLabel: { children: this.props.authUser.attributes['email'] },
                    NameTextField: {
                        defaultValue: this.state.company?.name,
                        onBlur: (event) => {
                            this.setState({
                                company:Company.copyOf(this.state.company, updated => {
                                    updated.name = event.target.value
                                })
                            })
                        }
                    },
                    WebsiteUrlTextField: {
                        defaultValue: this.state.company?.companyS3Uri,
                        onBlur: (event) => {
                            this.setState({
                                company:Company.copyOf(this.state.company, updated => {
                                    updated.companyS3Uri = event.target.value
                                })
                            })
                        }
                    },
                    CustomerServiceUrlTextField: {
                        defaultValue: this.state.company?.customeServiceS3Uri,
                        onBlur: (event) => {
                            this.setState({
                                company:Company.copyOf(this.state.company, updated => {
                                    updated.customeServiceS3Uri = event.target.value
                                })
                            })
                        }
                    },
                    CompanyLogoImage: { objectFit: "contain", backgroundColor: "white", 
                    src: this.state.companyLogoImageFileObj ? URL.createObjectURL(this.state.companyLogoImageFileObj):
                    this.state.companyLogoImageS3Uri ?? DEFAULT_BCKGND_IMG_URL,
                    onClick: (e) => {
                        this.companyLogoImageRef.current.click();
                    }},
                    SaveProfileButton: { onClick: (e) => {this.saveCompany()}},
                    LogOutButton: { onClick: (e) => {
                        this.navigate("/", { replace: false });
                        DataStore.clear().then(() => {
                            this.signOut().then(() => {
                                window.location.assign('/');
                        });
                    });
                    } },
                }}
                />
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(CompanyAccount);