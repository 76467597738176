/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { ItemAd } from "../models";
import {
  createDataStorePredicate,
  getOverrideProps,
  useDataStoreBinding,
} from "./utils";
import { SortDirection } from "@aws-amplify/datastore";
import ListedItemAd from "./ListedItemAd";
import { Collection } from "@aws-amplify/ui-react";
export default function ListedItemAdCollection(props) {
  const { items: itemsProp, overrideItems, overrides, ...rest } = props;
  const itemsFilterObj = { field: "isForSale", operand: "0", operator: "gt" };
  const itemsFilter = createDataStorePredicate(itemsFilterObj);
  const itemsPagination = {
    sort: (s) => s.updatedAt(SortDirection.DESCENDING),
  };
  const [items, setItems] = React.useState(undefined);
  const itemsDataStore = useDataStoreBinding({
    type: "collection",
    model: ItemAd,
    criteria: itemsFilter,
    pagination: itemsPagination,
  }).items;
  React.useEffect(() => {
    if (itemsProp !== undefined) {
      setItems(itemsProp);
      return;
    }
    async function setItemsFromDataStore() {
      var loaded = await Promise.all(
        itemsDataStore.map(async (item) => ({
          ...item,
          item: await item.item,
        }))
      );
      setItems(loaded);
    }
    setItemsFromDataStore();
  }, [itemsProp, itemsDataStore]);
  return (
    <Collection
      type="list"
      isSearchable="true"
      searchPlaceholder="Rehercher ..."
      direction="column"
      justifyContent="left"
      items={items || []}
      {...getOverrideProps(overrides, "ListedItemAdCollection")}
      {...rest}
    >
      {(item, index) => (
        <ListedItemAd
          itemAd={item}
          key={item.id}
          {...(overrideItems && overrideItems({ item, index }))}
        ></ListedItemAd>
      )}
    </Collection>
  );
}
