import React from 'react';
import withRouter from './withRouter';
import { updateS3SignedUrls } from "../data/s3"
import { CookiesAndCGUPage } from '../ui-components';
import { ViewportWidth, setViewport, DEFAULT_BCKGND_IMG_URL } from '../tools/ui';
import { DataStore, Auth } from 'aws-amplify';
import { User } from '../models';
import ModalWaiting from './ModalWaiting';
import { Loader, Flex } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';

class CookiesAndCGU extends React.Component {
    constructor(props) {
        setViewport(ViewportWidth.MOBILE);
        super(props);
        this.navigate = props.navigate;
        this.state = {
            user: undefined,
        };
    }


    componentDidMount() {
        DataStore.query(User, this.props.authUser.username).then(
            (user) => {
                this.setState({
                    user: user
                });
            }
        );
    }

    async saveUser() {
        this.setState({ showModalWaiting: true });
        if (this.state.user) {
            var userToSave = User.copyOf(this.state.user, updated => {
                updated.cguAcceptedDateTime = new Date().toISOString();
                updated.cookiesPolicyAcceptedDateTime = new Date().toISOString();
                updated.lastConnectionDateTime = new Date().toISOString();
            })
        } else {
            var userToSave = new User({
                id: this.props.authUser.username,
                email: this.props.authUser.attributes['email'],
                cguAcceptedDateTime: new Date().toISOString(),
                cookiesPolicyAcceptedDateTime: new Date().toISOString(),
                lastConnectionDateTime: new Date().toISOString()
            })
        }
        await DataStore.save(userToSave)

        setTimeout(() => {
            this.setState({ showModalWaiting: false, user: userToSave });
            this.navigate("/");
        }, 500);

    }

    async signOut() {
        try {
            //await Auth.signOut({ global: true });
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        return (
            <Flex direction="column" alignItems="center">
                <CookiesAndCGUPage hostname="" overrides={{
                    TitleBackButton: {onClick: (e) => { this.navigate("/",{ replace: false})}},
                    AcceptButton: { onClick: (e) => { this.saveUser() } },
                    RefuseButton: {
                        onClick: (e) => {
                            this.navigate("/", { replace: false });
                            if (this.props.authUser) {
                                DataStore.clear().then(() => {
                                    this.signOut().then(() => {
                                        window.location.assign('/');
                                    });
                                });
                            }
                        }
                    },
                }}
                />
                <ModalWaiting show={this.state.showModalWaiting}></ModalWaiting>
            </Flex>
        );
    }
}

export default withRouter(CookiesAndCGU);